import React, { useEffect, useState } from "react";
import Preview from "../../../components/previews/Preview";
import Input from "../../../components/Input";
import Card from "../../../components/Card";
import Button from "../../../components/Button";



const ProductPreview = ({ product, setPreviewProduct, handleUpdateCost }) => {
  const [productData, setProductData] = useState(product);
  const [variantsState, setVariantsState] = useState(product?.variants);

  const { id, title, variants = [] } = productData || {};
  // console.log("preview product", id)
  const handleChange = (id, newCost) => {
    const updatedVariants = variantsState.map((variant) =>
      variant.id === id
        ? { ...variant, cost: newCost || 0 } 
        : variant
    );
    setVariantsState(updatedVariants);
  };

  const updateProduct = () => {
    const updatedProduct = {
      ...productData,
      variants: variantsState,
    };
    setProductData(updatedProduct);
    handleUpdateCost(id, updatedProduct); 
    setPreviewProduct(null); 
  };

  return (
    <Preview
      showPreview={product}
      handleToggle={() => setPreviewProduct(null)}
      title={`Product - ${title}`}
    >
      <div className="flex flex-col max-h-[100%] overflow-auto gap-4 justify-between h-full">
        <div className="flex gap-4 flex-col">
          {variantsState?.length ? (
            <Card title="Costs" icon="BadgeDollarSign">
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2 max-h-[350px] variantBox overflow-auto gap-4">
                  {variantsState.map(({ title, price, cost = 0, id }) => (
                    <React.Fragment key={id}>
                      <Input label={title} value={price} disabled />
                      <Input
                        label={"Cost"}
                        type="number"
                        value={cost || ""}
                        onChange={(e) => handleChange(id, e.target.value)}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </Card>
          ) : undefined}
        </div>
        <div className="flex items-center mb-10 justify-end">
          <Button
            value="Cancel"
            type="secondary"
            onClick={() => setPreviewProduct(null)}
          />
          <Button
            value="Save"
            icon={{ name: "Save" }}
            onClick={updateProduct}
          />
        </div>
      </div>
    </Preview>
  );
};


export default ProductPreview;
