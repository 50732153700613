
import React from "react";
import Icon from "../Icon";

const Preview = ({children, showPreview,handleToggle, title}) => {
    return (
        <div className={`bg-black z-[100] bg-opacity-50 fixed top-0 right-0 bottom-0 flex justify-end  ${showPreview ? "w-full":"w-0 "}`}>
          <div className={`bg-white  shadow-2xl h-full w-[500px] rounded-l flex flex-col gap-4     transition-all ease-out duration-300 ${showPreview ? 'right-0 opacity-100' : "opacity-0 -right-[700px]"}`}>
            {showPreview && (
              <div className="h-full ">
                <div  className="">
                  <div className="flex px-5 py-3 items-center rounded-lg bg-white">
                    <button
                      onClick={handleToggle}
                      className="hover:rotate-180 origin-center transition"
                    >
                      <Icon name="X" color={"#2b4d75"}/>
                    </button>
                    <h1 className="text-[#2b4d75] text-xl font-bold px-4">
                      {title}
                    </h1>
                  </div>
                  <hr className="bg-[#CAD1DB]" />
                </div>
                <div className="bg-[#F8F9FB] h-full p-8">
                  {children}
                </div>
              </div>
            )}
          </div>
        </div>
    )
}

export default Preview 