import React, { useState, useRef } from "react";
import Input from "../../components/flowbite/Input";
import { Icon } from "../../components";
import { useAppContext } from "../../context/Context";
import Button from "../../components/Button";
import { useErrorContext } from "../../context/ErrorContext";
import api from "../../utils/api";
import { Eye, EyeOff } from "lucide-react";
import SubscriptionDetails from "./SubscriptionDetails";
import Card from "../../components/Card";
import { useNavigate } from "react-router-dom";
import { removeToken, removeUser } from "../../utils/localStorage";

const MyProfile = () => {
  const { userData, setIsUserUpdate, isUserUpdate } = useAppContext();
  const { setStatus } = useErrorContext();
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    firstname: userData.firstname || "",
    lastname: userData.lastname || "",
    email: userData.email || "",
    fullname: userData.fullname || "",
    phoneNumber: userData.phoneNumber || "",
  });

  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordIsLoading, setPasswordIsLoading] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const fileInputRef = useRef(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [generatingOtp, setGeneratingOtp] = useState(false);


  const handleLogout = () => {
    removeToken();
    removeUser();
    navigate("/login");
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProfileUrl(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      if (formValue.firstname && formValue.firstname !== userData.firstname) {
        formData.append("firstname", formValue.firstname);
      }
      if (formValue.lastname && formValue.lastname !== userData.lastname) {
        formData.append("lastname", formValue.lastname);
      }
      if (formValue.fullname && formValue.fullname !== userData.fullname) {
        formData.append("fullname", formValue.fullname);
      }
      if (
        formValue.phoneNumber &&
        formValue.phoneNumber !== userData.phoneNumber
      ) {
        formData.append("phoneNumber", formValue.phoneNumber);
      }

      if (profileUrl) {
        formData.append("profileImage", profileUrl);
      }

      if (
        formData.has("firstname") ||
        formData.has("lastname") ||
        formData.has("fullname") ||
        formData.has("phoneNumber") ||
        formData.has("profileImage")
      ) {
        const res = await api.put("/profile-update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setIsUserUpdate(!isUserUpdate);
        setIsLoading(false);
      } else {
        console.log("No changes made to the profile.");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 500) {
        setStatus(false);
      }
    }
  };

  const handleEmailChange = async () => {
    setIsEditEmail(true);
    if (formValue.email !== userData.email) {
      try {
        const res = await api.put("/change-email", {
          email: formValue.email,
        });
        setIsEditingEmail(false);
        setIsUserUpdate(!isUserUpdate);
        setIsEditEmail(false);
      } catch (error) {
        setIsEditingEmail(false);
        setStatus(false);
      }
    }
  };

  const handlePasswordChange = async () => {
    setPasswordIsLoading(true);
    if (newPassword) {
      try {
        const res = await api.post("/change-password", {
          password: newPassword,
        });
        setIsEditingPassword(false);
        setIsUserUpdate(!isUserUpdate);
        setPasswordIsLoading(false);
      } catch (error) {
        setStatus(false);
        setPasswordIsLoading(false);
      }
    }
  };

  const onVerifyEmailHandler = async () => {
    const email = userData?.email;
    if (!email) return;

    setGeneratingOtp(true);

    try {
      await api.post("/verify-email", {
        email: email,
      });

      navigate("/verify-email", {
        state: {
          email
        }
      });

    } catch (error) {
      console.log(error);
    }

    setGeneratingOtp(false);
  }

  return (
    <div className="w-[100%-64px] gap-7 h-fit flex ">
      <div className="w-full flex flex-col gap-5">
        <Card>
          <div className="flex  items-center  gap-2">
            <img
              className="w-14 h-14 rounded-full cursor-pointer"
              src={
                selectedImage ||
                userData.profileImage ||
                "https://cdn-icons-png.flaticon.com/512/149/149071.png"
              }
              alt="Profile Avatar"
            />

            <div className="flex flex-col justify-center">
              <h2 className="text-xl font-semibold">{userData.fullname}</h2>
              <p className="text-sm text-gray-500">{userData.email}</p>
            </div>
          </div>
          <hr className="h-px my-5 bg-gray-200 border-0 dark:bg-gray-700" />
          <div className="flex items-center gap-2">
            <Icon name="CircleUserRound" size={20} color="#0072ff" />
            <p className="text-sm text-[#3a8efc]">My Profile</p>
          </div>
        </Card>
        <div>
          <SubscriptionDetails />
        </div>
        <div className="flex justify-end">
          <Button
            onClick={handleLogout}
            type="danger"
            value={"Logout"}
          />
        </div>
      </div>

      <Card>
        <div className="mb-3">
          <h2 className="text-lg font-semibold">Personal Information</h2>
        </div>
        <div className="mb-3">
          <img
            className="w-14 h-14 rounded-full cursor-pointer"
            src={
              selectedImage ||
              userData.profileImage ||
              "https://cdn-icons-png.flaticon.com/512/149/149071.png"
            }
            alt="Profile Avatar"
            onClick={handleAvatarClick}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <Input
              name="firstName"
              value={formValue.firstname}
              onChange={(e) =>
                setFormValue({ ...formValue, firstname: e.target.value })
              }
              label="First name"
              placeholder="Enter first name"
            />
            <Input
              name="lastName"
              value={formValue.lastname}
              onChange={(e) =>
                setFormValue({ ...formValue, lastname: e.target.value })
              }
              label="Last name"
              placeholder="Enter last name"
            />
            {/* <Input
              name="phoneNumber"
              value={formValue.phoneNumber}
              onChange={(e) =>
                setFormValue({ ...formValue, phoneNumber: e.target.value })
              }
              label="Phone number"
              placeholder="Enter phone number"
            /> */}
            <Button
              className="h-fit self-end"
              disabled={isLoading}
              value="Save"
              onClick={(e) => handleSaveChanges(e)}
            />
          </div>

          <div className="flex items-center gap-16">
            {/* <Input
              name="fullname"
              value={formValue.fullname}
              onChange={(e) =>
                setFormValue({ ...formValue, fullname: e.target.value })
              }
              label="Full name"
              placeholder="Enter full name"
            /> */}
            {/* <Button
              disabled={isLoading}
              value="Save Changes"
              onClick={(e) => handleSaveChanges(e)}
            /> */}
          </div>

          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />

          <div className="mb-3">
            <label className="block text-base font-bold text-gray-700">
              Email Address
            </label>
            {isEditingEmail ? (
              <>
                <Input
                  type="email"
                  value={formValue.email}
                  onChange={(e) =>
                    setFormValue({ ...formValue, email: e.target.value })
                  }
                  placeholder="Enter email address"
                />
                <Button
                  disabled={isEditEmail}
                  value="Save Email"
                  className="mt-4"
                  onClick={(e) => handleEmailChange(e)}
                />
              </>
            ) : (
              <>
                <p className="mt-1 text-sm text-gray-500">{userData.email}</p>
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    onClick={() => setIsEditingEmail(true)}
                    className="mt-3 bg-blue-500 border border-gray-300 px-4 py-2 rounded-md hover:bg-blue-600"
                  >
                    Change Email Address
                  </button>
                  {
                    !userData?.emailVerified &&
                    <button
                      type="button"
                      onClick={onVerifyEmailHandler}
                      className="mt-3 bg-blue-500 border border-gray-300 px-4 py-2 rounded-md hover:bg-blue-600 cursor-pointer disabled:bg-slate-400 disabled:text-white disabled:cursor-not-allowed"
                      disabled={generatingOtp}
                    >
                      Verify Email
                    </button>
                  }
                </div>
              </>
            )}
          </div>

          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />

          <div className="mb-3">
            <label className="block text-base font-bold text-gray-700">
              Password
            </label>
            {isEditingPassword ? (
              <>
                <div className="relative">
                  <Input
                    type={isPasswordVisible ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? (
                      <EyeOff size={20} />
                    ) : (
                      <Eye size={20} />
                    )}{" "}
                  </button>
                </div>
                <Button
                  disabled={passwordIsLoading}
                  value=" Save Password"
                  className="mt-4"
                  onClick={(e) => handlePasswordChange(e)}
                />
              </>
            ) : (
              <>
                <p className="mt-1 text-sm text-gray-500">********</p>
                <button
                  type="button"
                  onClick={() => setIsEditingPassword(true)}
                  className="mt-3 bg-blue-500 border border-gray-300 px-4 py-2 rounded-md hover:bg-blue-600"
                >
                  Change Password
                </button>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MyProfile;
