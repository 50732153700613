import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "../components/Icon";
import Input from "../components/Input";
import API from "../utils/api";
import {
  statusBodyTemplate,
  imagyTextBodyTemplate
} from "../components/table/Cells";
import { validateDomainUrl } from "../utils/helper" ;
const { fetchStoresService, defaultFetchState} = require("../utils/services");

// check  is used to check if the component must re render
const Products = ({
  check
}) => {
  const [storesState, setStoresState] = useState(defaultFetchState);
  useEffect(() => {
    
    const fetchStoresEffect = async () => {
      setStoresState({ ...storesState, loading: true })
      setStoresState(await fetchStoresService());
    };
    if(!check){
      fetchStoresEffect();
    }
  }, [check]);

  const EditableTextBody = (rowData) => {
    const [isEditing, setIsEditing] = useState(false);
    return (
      <div>
        { !isEditing ?
        <div className="flex items-center gap-2 cursor-pointer justify-between" onClick={() => setIsEditing(true)}>
          <div>{rowData.customDomain} </div>
          <div>
            <Icon name="Pencil" size="16" />
          </div>
        </div>
          :
          <CustomDomainBody rowData={rowData} setIsEditing={setIsEditing}/>
        }
      </div>
    );
  };

  const CustomDomainBody = ({rowData, setIsEditing}) => {
    const originalValue = rowData.customDomain
    const [newValue, setNewValue] = useState(originalValue);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    useEffect(() => {
      setIsValid(validateDomainUrl(newValue));
    }, [newValue]);

    const updateStore = async () => {
      if(isValid === false) return
      try {
        setIsloading(true);
        const updatedStore = { ...rowData, customDomain: newValue };
        await API.put(`/stores/${rowData.id}`, updatedStore);
        setStoresState({ ...storesState, data: storesState.data.map(store => store.id === rowData.id ? { ...store, customDomain: newValue } : store) });
        setIsEditing(false)
      } catch (error) {
        console.error("Error updating store:", error);
      }
      setIsloading(false);
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        updateStore();
      } else if (e.key === "Escape") {
        setIsEditing(false);
      }
    };
    
    return (
      <div className="flex items-center gap-2">
        <Input
          disabled={isLoading}
          type="text"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div className="flex gap-2 items-center cursor-pointer">
          <div  onClick={updateStore}><Icon name="Save" color={isValid ? "#5a5c73":"#F5432C"} size="16"/></div>
          <div onClick={() => setIsEditing(false)}><Icon  name="X" color="#F5432C" size="16" /></div>
        </div>
      </div>
    );
  };

  return (
    <div className="my-2 rounded mx-4 shadow">
      <DataTable
        value={storesState?.data }
        loading={storesState.loading}
        dataKey="id"
        paginator
        rows={5}
        stripedRows
        showGridlines
        emptyMessage="No available data"
      >
        <Column 
          field="shopName" 
          header="Store Name"
          body={(rowData) => imagyTextBodyTemplate(rowData.shopName, "images/platform_shopify.svg")}
        />
        <Column
          field="status"
          header="Status"
          body={(rowData) => statusBodyTemplate(rowData.status)}
        />
        <Column
          field="customDomain"
          header="Custom Domain"
          // editor={(options) => <TextEditor  options={{...options}} />}
          // body={CustomDomainBody}
          style={{ width: "20%" }}
          body={EditableTextBody}
        />
      </DataTable>
    </div>
  );
};

export default Products;
