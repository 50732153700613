import { useEffect, useState } from "react";
import api from "../utils/api";
import { useAppContext } from "../context/Context";

const useUserPlan = () => {

   const { setUserPlan } = useAppContext();
   const [userPlanLoading, setUserPlanLoading] = useState(true);

   const getUserPlan = async () => {
      setUserPlanLoading(true);
      try {
         const res = await api.get("/getUserPlan");

         if (res?.data?.userPlan) {
            setUserPlan(res.data.userPlan);

            // navigate(location.pathname);
         }

      } catch (error) {
         console.log(error);
      }
      setUserPlanLoading(false);
   }

   useEffect(() => {
      getUserPlan();
   }, [])

   return { userPlanLoading }
};

export default useUserPlan;
