import React from "react";
import Button from "../components/Button";
import Preview from "../components/previews/Preview";
const Page = ({
  title,
  subtitle,
  actions = [],
  children,
  fullWidth = true,
}) => {
  return (
    <div className={`h-screen ${fullWidth ? "" : "max-w-[1000px] m-auto"}`}>
      <div className="p-3 flex flex-col gap-7 w-full">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-1">
            <h1 className=" text-2xl font-bold leading-10">{title}</h1>
            {subtitle && <p className="text-sm ">{subtitle}</p>}
          </div>
          
          <div className="flex items-center justify-center gap-4">
            {actions.map(({ action, name, type, icon }, index) => {
              return (
                <Button
                  key={index}
                  onClick={action}
                  value={name}
                  type={type}
                  icon={icon}
                />
              );
            })}
          </div>
        </div>
        {children}
        {/* <div className="flex flex-col ">
          <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-black-100">
            {" "} If you have any questions or need assistance {" "}
            <a onClick={handleContactUs}  className="text-[#0D6EFD] cursor-pointer" >
              Contact Us
            </a>
          </p>
        </div> */}
        <Preview />
      </div>
    </div>
  );
};

export default Page;
