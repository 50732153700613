import axios from "axios";
import { toast } from "react-hot-toast";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});
// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    const user = JSON.parse(localStorage.getItem("user"));

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (user?.workspace?.code) {
      config.headers['X-Workspace'] = user.workspace.code;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it

    if (error?.response?.status === 403) {
      if (error?.response?.status === 403 && window.location.pathname !== "/subscription") {
        window.location.href = "/subscription";
      }

    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await axios.post(
          `${process.env.REACT_APP_API}/get-auth-token`,
          { refreshToken }
        );
        const { token } = response.data.data;
        localStorage.setItem("authToken", token);
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      } catch (error) {
        console.error(error, "Failed to refresh token");
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    toast.error(error?.response?.data?.message || "Oops! Something went wrong");
    return Promise.reject(error);
  }
);
export default api;
