import React, {   useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "../Icon";
import { useTableContext } from "../../context/TableContext";
import {
  imagyTextBodyTemplate,
  statusBodyTemplate,
} from "./Cells";
import { deleteAdAccountService } from "../../utils/services"
import Modal from "../Modal";


const AdAccountsTable = ({adAccountsAction}) => {
    const {
      setSelectedItem,
    } = useTableContext();

    const [
      {
          data,
          loading,
      }, 
      setAdAccountsState,
    ] = adAccountsAction;

    const [toDelete, setToDelete] = useState(null);
 
    const handleDelete = async () => {
        try {
          const { error, data: success } = await deleteAdAccountService(toDelete._id);
          if (error) {
            console.error("Error deleting product:", error);
            return;
          }
          if (success) {
            setAdAccountsState({
              ...data,
              data: data.filter((adAccount) => adAccount._id !== toDelete._id),
            });
          }
          setToDelete(null);
        } catch (error) {
          console.error("Error removing product:", error);
        }
      }


    const actionBodyTemplate = (rowData) => {
        return (
          <div className="flex  gap-3 cursor-pointer">
            <div onClick={() => setSelectedItem(rowData)}>
              <Icon name="Pencil" size="16" />
            </div>
            <div onClick={() => setToDelete(rowData)}>
              <Icon name="Trash" size="16" color="red" />
            </div>
          </div>
        );
      };
      
    return (
        <div className="my-3 rounded mx-4 shadow">
            <DataTable
            value={data}
            loading={loading}
            stripedRows
            showGridlines
            paginator
            rows={10}
            tableStyle={{ minWidth: "50rem" }}
            emptyMessage="No available data"
            >
                <Column
                    field="name"
                    header="Name"
                    body={(rowData) => imagyTextBodyTemplate(rowData.name, rowData.image)}
                />
                <Column
                    field="accountUserId"
                    header="#ID"
                />
                <Column
                    field="businessName"
                    header="Business Name"
                />
                <Column
                  field="status"
                  header="Status"
                  body={(rowData) => statusBodyTemplate(rowData.status)}
                />
                <Column
                  header="Actions"
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "12rem" }}
                />
            </DataTable>
            <Modal 
                showModal={toDelete}
                setShowModal={() => setToDelete(null)}
                title="Delete AdAccount"
                onClose={() => setToDelete(null)}
                actions={[
                { value: "Delete", type:"danger", onClick: handleDelete ,icon:{name:"Trash", color:"#F5432C"}}
                ]}
            >
                <div className="text-[#2b4d75] flex flex-col gap-3">
                    <p>Are you sure you want to delete this AdAccount?</p>
                    <p className="text-bold mx-4"> * {toDelete?.name}</p>
                </div>
            </Modal>
        </div>
    )   
}

export default AdAccountsTable;