import { createContext, useContext, useEffect, useState} from "react";

export const errorContext = createContext()

export const ErrorContextProvider = ({ children }) => {
    const [status, setStatus] = useState(true)

    useEffect(() => {
        setStatus(true)
    }, [])

    return (
        <errorContext.Provider value={{status, setStatus}}>
            {children}
        </errorContext.Provider>
    )
}   

export const useErrorContext = () => {
   return useContext(errorContext)
}