import React, { useState } from 'react';
import { ReactComponent as Arrows } from '../../svgs/arrows.svg';
import './styles.css';
import { useNavigate } from "react-router";
import ChooseYourSystem from './Components/ChooseYourSystem'
import IntegrationSteper from './IntegrationSteper';
import ShopifyIntegration from './Shopify/ShopifyIntegration';
import YouCanIntegration from './YouCan/YouCanIntegration';


const CreateIntegration = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedSystem, setSelectedSystem] = useState(null);
    const [stepError, setStepError] = useState(false);
    const navigate = useNavigate();
    
    
    const handleBack = () => {
            // setTransitionClass("backward");
            setActiveStep(prevStep => prevStep - 1);
    };
     
    const handleFinish = () => navigate("/integration");
        
    const handleContinue = () => {
        setActiveStep(prevStep => prevStep + 1);
    }
    
    
    const steps = [
        { label: "Choose Integration", description: "Select the type of integration", detail: "Pick from our available integration options" },
        { label: "Input Domain", description: "Add domain details", detail: "Provide domain configurations" },
        { label: "Configure Integration", description: "Adjust settings", detail: "Set custom configurations based on your platform" },
        { label: "Thank You", description: "Finish setup", detail: "Thank you for setting up your integration" }
    ];

    const renderIntegrationType = () => {
        switch (selectedSystem) {
            case "Shopify":
                return <ShopifyIntegration handleContinue={handleContinue} handleBack={handleBack} activeStep={activeStep}/>;
            case "Youcan":
                return <YouCanIntegration  handleContinue={handleContinue} handleBack={handleBack} activeStep={activeStep}/>;
            default:
                return <p>Error render Integration Type</p>;
        }
    }

    const StoreIcon = () => {
        if(activeStep === 0) 
            return <img src="/images/logos/store.png" alt="Store" className="w-16 h-16 object-contain" />
        switch(selectedSystem) {
            case "Shopify":
                return <img src="/images/logos/shopify.png" alt="Shopify" className="w-16 h-16 object-contain" />
            case "Youcan":
                return <img src="/images/logos/youcan.png" alt="Youcan" className="w-16 h-16 object-contain" />
            default:
                return <img src="/images/logos/store.png" alt="Store" className="w-16 h-16 object-contain" />
        }
    }
        
    return (
        <div className="flex h-screen bg-gray-50">
            <div className="transition-container h-screen max-w-[1000px] m-auto flex flex-col items-center">
                <div className={`transition-container`}>
                    <div className="flex-1 p-6 flex flex-col justify-center items-center">
                        {activeStep < 3 && (
                        <div className="flex items-center justify-center gap-8 mt-16 mb-9">
                                <img src="/images/logos/adclair.png" alt="Adclair" className="w-16 h-16 object-contain" />
                                <Arrows className="w-20 h-8 text-gray-400" />
                                <StoreIcon />
                        </div>)}
                        <div className="max-w-4xl mx-auto px-4 py-4 flex flex-col items-center mb-9">
                            {activeStep === 0 && <ChooseYourSystem onSelect={setSelectedSystem} selectedSystem={selectedSystem} setStepError={setStepError} handleContinue={handleContinue} handleFinish={handleFinish}/>}
                            {activeStep > 0 && renderIntegrationType()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-96 border-l border-gray-200">
                <IntegrationSteper steps={steps} activeStep={activeStep} />
            </div>
        </div>
    );
}

export default CreateIntegration
