import { useEffect, useState } from 'react'
import api from '../utils/api';

const useNews = () => {
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const fetchNews = async () => {
        setLoading(true);
        try {
            const res = await api.get("/news");
            setNews(res.data.news);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchNews();
    }, [])

    return { loading, news }
}

export default useNews