import React, { useState } from "react";
import TableCamp from "./Table";
import PaginationComp from "../PaginationComp";
import { useTableContext } from "../../context/TableContext";
import EmptyState from "../EmptyState";

const TableContainer = () => {
  const { emptyState, isDashboard } =
    useTableContext();

  return (
    <>
      <div className={`px-4 flex flex-col rounded-lg ${isDashboard? '': 'py-3'}`}>
        <div className="flex justify-between items-center">
        </div>
        <TableCamp />
        <PaginationComp />
      </div>
    </>)
  // ) : (
  //   <EmptyState {...emptyState} />
  // );
};

export default TableContainer;
