import React from "react";
import Icon from "./Icon";
import { Spinner } from "flowbite-react/components/Spinner";

const Button = ({
  type = "primary",
  onClick,
  icon,
  value,
  disabled,
  className = "",
  loading,
  before=true
}) => {
  const handleClick = () => {
    if (!disabled && !loading && onClick) {
      onClick();
    }
  };
  return (
    <button
      className={`
            flex rounded-lg gap-2 px-3 py-2 transition justify-center items-center ${className} 
            ${type === "primary" ? " bg-blue text-[#fff]  " : ""}
            ${type === "secondary" ? "  text-blue  " : ""}
            ${type === "danger" ? " bg-red text-[#fff] " : ""}
            ${
              disabled || loading
                ? " opacity-50 cursor-not-allowed	"
                : " cursor-pointer"
            }
        `}
      onClick={handleClick}
    >
      {icon && before && !loading && <Icon name={icon.name} color={icon.color} />}
      {loading && before && <Spinner aria-label="Default status example" />}
      <div className="">{value}</div>
      {icon && !before && !loading && <Icon name={icon.name} color={icon.color} />}
      {loading && !before && <Spinner aria-label="Default status example" />}
    </button>
  );
};

export default Button;
