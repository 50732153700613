import React from 'react'

const Loading = () => {
    return (
        <div className="h-screen  bg-gray-100  flex items-center">
            <div className="flex flex-col w-full items-center justify-center">
                <div className="w-[100px] rounded">
                    <img
                        src="images/logos/logo-black-short.jpeg"
                        className="w-[100px] rounded"
                    />
                </div>
                <div>Loading...</div>
            </div>
        </div>
    )
}

export default Loading