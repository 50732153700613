import React, { useEffect, useState } from "react";
import { TableContextProvider } from "../context/TableContext";
import Page from "./Page";
import TableContainer from "../components/table/TableContainer";
import PublisherModal from "../components/modals/PublisherModal";
import { invitationsConfiguration as tableConfiguration } from "../constant/tablesParam";
import api from "../utils/api";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../context/Context";
import { useErrorContext } from "../context/ErrorContext";
const Invitations = () => {
  const { userData } = useAppContext();
  const [showPoblisherModal, setShowPoblisherModal] = useState(false);
  const { setStatus } = useErrorContext()


  const actions = [
    {
      name: "Invite",
      action: () => setShowPoblisherModal(true),
      type: "primary",
      icon: {
        name: "UserPlus",
        color: "white",
      },
    },
  ];

  const emptyState = {
    actions,
    title: "Invite your first team member",
    subtitle:
      "Invite collaborators to your team to manage and publish cmapaigns ",
    image: "invite.png",
  };

  const [invitedUserList, setInvitedUserList] = useState([]);
  const fetchUsers = async () => {
    try {
      setStatus(true)
      const res = await api.get("/get-invited-users");
      setInvitedUserList(res.data.data);
      console.log(res.data.data);
    } catch (e) {
      console.log(e);
      if(e.response.status === 500) {
        setStatus(false)
      }
    }
  };
  useEffect(() => {
    fetchUsers();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userData.role === "admin" ? (
    <Page title="Invitations" actions={actions}>
      <TableContextProvider
        {...{ tableConfiguration, data: invitedUserList, emptyState }}
      >
        <PublisherModal
          setShowPoblisherModal={setShowPoblisherModal}
          showPoblisherModal={showPoblisherModal}
        />
        <TableContainer title={"Invitations"} />
      </TableContextProvider>
    </Page>
  ) : (
    <Navigate to={"/"} />
  );
};

export default Invitations;
