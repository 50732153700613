import { useState } from "react";
import toast from "react-hot-toast";
import {
  hadleS3Upload
} from '../../utils/services'

const FileUpload = ({ label, value, bindContext }) => {
  const [loading, setLoading] = useState(false)

  const handleChange = async (e) => {
    setLoading(true);
    try {
      const file = e.target?.files[0];
      const response = await hadleS3Upload( [file]);
      const [fileName] = response[0]

      if(!fileName){
        throw new Error("Failed to upload the media, please try again")
      }
      
      const thumbnailUrl =   "https://monsterocu-dev-publicfiles.s3.eu-west-2.amazonaws.com/" + fileName

      if (bindContext) {
        const [{ setItem }, path] = bindContext;
        setItem(path, thumbnailUrl);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to upload the media, please try again")
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        htmlFor="file_input"
      >
        {label}
      </label>
      <input
        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        id="file_input"
        type="file"
        accept="image/*"
        onChange={handleChange}
      />
    </div>
  );
};

export default FileUpload;
