import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { Icon } from '../components';
import { Link } from 'react-router-dom';

const WorkspaceSelection = () => {
    const [workSpaceData, setWorkSpaceData] = useState([])

    async function fetchWorkspaceData() {
        try {
            const res = await api.get('/user/workspaces');
            console.log(res.data, "Userdats")
            setWorkSpaceData(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchWorkspaceData()
    }, [])

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="bg-white h-full rounded-lg p-6 w-96">
                <div className="flex justify-center mb-6">
                    <div className="flex items-center h-10 w-48">
                        <img src="/images/logos/logo_black_long.png" />
                    </div>
                </div>
                <h1 className="text-xl font-semibold text-center mb-6">Workspaces</h1>
                <p className="text-center mb-6">Select the store you want to log in to.</p>
                <div className="space-y-4 mt-6 min-h-[300px]">
                    {workSpaceData?.map((workspace, index) => (
                        <div
                            key={index}
                            className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition cursor-pointer"
                        >
                            <div className="flex items-center">
                                <img
                                    src={workspace.image}
                                    alt={`${workspace.name} avatar`}
                                    className="w-10 h-10 rounded-full mr-4"
                                />
                                <div>
                                    <h2 className="font-semibold">{workspace.name}</h2>
                                    <p className="text-sm text-gray-600">{workspace?.store}</p>
                                </div>
                            </div>
                            <span className="px-3 py-1 text-sm shadow-sm border border-[#6b7280]/30 rounded-full">Admin</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="text-center mt-6">
                <Link to="/" className="text-gray-500 flex gap-3 hover:underline">
                    <span>
                        <Icon name={"MoveLeft"} color={"#6b7280"} />
                    </span>
                    Use another account
                </Link>
            </div>
        </div>
    );
};

export default WorkspaceSelection;
