export const addUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const addToken = (token) => {
  localStorage.setItem("authToken", token);
};
export const addRefresh = (token) => {
  localStorage.setItem("refreshToken", token);
};

export const getUser = () => {
  const result = localStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;
  return user;
};
export const getToken = () => {
  const result = localStorage.getItem("authToken");
  return result ? result : null;
};

export const removeUser = () => {
  localStorage.removeItem("user");
};

export const removeToken = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
};
