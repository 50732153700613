import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./context/Context";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { ErrorContextProvider } from "./context/ErrorContext";
import { Toaster } from "react-hot-toast";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"],
});
BugsnagPerformance.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ErrorContextProvider>
        <ContextProvider>
          <App />
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 5000,
            }}
          />
        </ContextProvider>
      </ErrorContextProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
