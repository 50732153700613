export const campaignData = [
  {
    id: "665b18536d9f124ccaf937b5",
    status: "INACTIVE",
    name: "camp1",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d9f124ccaf937bmndkwd",
    status: "ACTIVE",
    name: "camp1",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d9f124ccaf9376565252",
    status: "INACTIVE",
    name: "camp1",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d9f124ccaf93856",
    status: "INACTIVE",
    name: "camp1",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d9f124ccaf93765856352",
    status: "INACTIVE",
    name: "camp1",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
];
export const setAds = [
  {
    id: "665b18562d9f124ccaf937b5",
    status: "INACTIVE",
    name: "camp2",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d95264ccaf937bmndkwd",
    status: "ACTIVE",
    name: "camp2",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d85424ccaf9376565252",
    status: "INACTIVE",
    name: "camp2",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b1858532f124ccaf93856",
    status: "INACTIVE",
    name: "camp2",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536245424ccaf93765856352",
    status: "INACTIVE",
    name: "camp2",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
];

export const ads = [
  {
    id: "665b18536753124ccaf937b5",
    status: "INACTIVE",
    name: "camp3",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d9f951ccaf937bmndkwd",
    status: "ACTIVE",
    name: "camp3",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d98524ccaf9376565252",
    status: "INACTIVE",
    name: "camp3",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d9f258ccaf93856",
    status: "INACTIVE",
    name: "camp3",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
  {
    id: "665b18536d9f124cca786765856352",
    status: "INACTIVE",
    name: "camp3",
    daily_budget: 2000,
    insights: {
      data: [
        {
          spend: 0,
        },
      ],
    },
    netProfit: 0, // Assuming netProfit needs to be calculated or provided
    page: {
      picture:
        "https://scontent-fra5-2.xx.fbcdn.net/v/t39.30808-1/312415582_494284742752723_1148156326935255525_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=pPnPWrftjwMQ7kNvgHTdFXW&_nc_ht=scontent-fra5-2.xx&edm=AGaHXAAEAAAA&oh=00_AYDOA07_kFD1MVwKnkUq8qS7wdWPOdvjp_fOwfKPzZOTmQ&oe=6660E11C",
      name: "Croissant Corner",
      id: "110350431114538",
    },
    product: {
      coverImage:
        "https://cdn.shopify.com/s/files/1/0566/8841/0735/products/Main_52f8e304-92d9-4a36-82af-50df8fe31c69.jpg?v=1707240861",
      title: "The Archived Snowboard",
      id: "7234417426543",
      storeName: "aayoub-e.myshopify.com",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard",
    },
    pixel: {
      is_unavailable: false,
      name: "zaynaba",
      id: "2673642629590491",
    },
    adCopy: "an add copy",
    adAccountId: {
      id: "1618209285004051",
      ownerId: "665a1cc7844829362a390a0c",
      accountUserId: "7469323629779142",
      platform: "facebook",
      businessName: "-",
      currency: "USD",
      image: "",
      status: "active",
      __v: 0,
    },
    adData: {
      campaignId: "120209910337440372",
      adsetId: "120209910337540372",
      adId: "120209910338280372",
      link: "https://aayoub-e.myshopify.com/products/the-archived-snowboard?utm_source=facebook&utm_campaign=120209910337440372&utm_medium=120209910337540372&utm_term=120209910338280372",
    },
    conversion: "0",
    publisherName: "Me",
    createdBy: "665a1cc7844829362a390a0c",
    owner: "Admin ayoub",
    isActive: false,
    createdAt: "2024-06-01T12:47:15.575Z",
    updatedAt: "2024-06-01T12:47:15.575Z",
    __v: 0,
  },
];
