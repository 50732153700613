import { setIn, removeIn } from 'immutable';

export const SET_ITEM = 'SET_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_ALL = 'SET_ALL';

export const SetItemReducer = (state, { type, path, value }) => {
	try {
		switch (type) {
			case SET_ITEM:
				return setIn(state, path.split('.'), value);
			case SET_ALL:
				return value;
			case DELETE_ITEM:
				return removeIn(state, path.split('.'));
			default:
				return state;
		}
	} catch (error) {
		console.log('Reducer error', { state, path, value });
		return state;
	}
};
