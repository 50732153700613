import { Button } from "flowbite-react/components/Button";

const  FlowButton = ({
  value,
  onClick,
  postIcon,
  preIconm,
  size = "md",
  color = "blue",
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button onClick={handleClick} size={size} color={color}>
      {value}
    </Button>
  );
}

export default FlowButton;