import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Api from '../utils/api';
import { getIntegration } from '../utils/services';

export const IntegrationContext = createContext();

export const IntegrationContextProvider = ({ children }) => {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

const fetchIntegrations = async () => {
  try {
    setLoading(true);
    const response = await getIntegration();
    if (response.data && Object.keys(response.data).length > 0) {
      setIntegrations(response.data.integrations);
    } else {
      setIntegrations([]);
      setError("No integrations found");
    }
  } catch (err) {
    setError(err.error ? err.error.message : "Failed to fetch integrations");
  } finally {
    setLoading(false);
  }
};
  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <IntegrationContext.Provider value={{ 
        integrations, 
        setIntegrations, 
        loading, 
        setLoading,
        error,
        setError,
      }}>
      {children}
    </IntegrationContext.Provider>
  );
};
