import React from "react";

const TermsOfUse = () => {
  return (
    <div className="text-[#aaa] py-4" style={{ background: "#080f18" }}>
      <div className={"w-full max-w-[1000px] mx-auto flex flex-col gap-2 px-4"}>
        <header className="entry-header">
          <h1 className="font-extrabold text-[#efefef] text-yellow font-circularBold text-4xl leading-[150%] mb-4">
            AdClair — Terms of Use
          </h1>
        </header>
        <div className="entry-content font-walsheim">
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            These Terms of Use are effective as of May 18, 2020.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Welcome to AdClair! These Terms of Use (“Terms”) apply to your (“you” or “your”) use of AdClair’s visual communication platform (the “Service”). By using the Service, you agree that these terms will become a legally binding agreement between you and the AdClair Contracting Entity identified in these Terms (“AdClair”).
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          You’ll find simple explanations of our Terms in these boxes, but keep in mind only the Terms outside these boxes are legally binding.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Overview
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            AdClair is an automated advertising platform that empowers small businesses to advertise in an easy and profitable way.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          The Service is made available on AdClair.com, AdClair Shopify apps, and in other forms provided or made available by AdClair. By using the Service you are agreeing to AdClair’s Privacy Policy.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            You may use the Service only if you can form a binding contract with AdClair and are legally permitted to do so. By using the Service, you represent and warrant that you have the full right, power and authority to agree to and be bound by these Terms and to fully perform all of your obligations hereunder.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          If you sign up for the Service on behalf of an organization using an email address provided by your employer or another organization, (i) you represent and warrant that you are an authorized representative of that entity with authority to bind that entity to these Terms; (ii) your use of the Service will bind that entity to these Terms; and (iii) “you” and “your” in these Terms will refer to both you and that entity.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Our AdClair for Agency plan is intended for agencies, businesses and organizations of all sizes who want to work and advertise together. If you create an Agency plan on behalf of an organization or employer, you are binding them to these Terms and all the obligations set out in them. If they haven’t authorized you to do this, you’ll need someone who is authorized to create the Team.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Using the Service
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Age Requirement</u></strong>. Our Service does not address anyone under the age of 18 (“Children”).
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We do not knowingly collect personally identifiable information from anyone under the age of 18.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Access to the Service. Subject to your compliance with these Terms, you are granted a non-exclusive, limited, non-transferable, freely revocable license to access and use the Service for business or personal use. AdClair reserves all rights not expressly granted under these Terms. Each person must have a unique account and you are responsible for any activity conducted on your account.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Anti-discrimination</u></strong>. AdClair does not support and will not tolerate its Service being used to discriminate against others, especially when based on race, religion, sex, sexual orientation, age, disability, ancestry or national origin. You are not permitted to use the Service in a manner which would or would likely incite, promote or support such discrimination and you must not use the Service to incite or promote hostility or violence.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We believe in Being a Force for Good in the world and that means we don’t tolerate AdClair being used in inappropriate ways.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Restrictions on Use of the Service</u></strong>. You shall not yourself or through any third party (i) rent, lease, sell, distribute, offer in a service bureau, sublicense, or otherwise make available the Service or the Licensed Content to any third party (except as permitted under these Terms); (ii) copy, replicate, decompile, reverse-engineer, attempt to derive the source code of, modify, or create derivative works of the Service, or any part thereof; (iii) access the Service for purposes of performance benchmarking; (iv) access the Service for purposes of building or marketing a competitive product; (v) use the Service to store or transmit a virus or malicious code; (vi) use a virtual private network (VPN) to circumvent geographic-based pricing or content access; (vii) use the Service to transmit unsolicited emails or engage in spamming; or (viii) bypass the measures we may use to prevent or restrict access to the Service.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We work hard to make AdClair available to everyone, so we can’t allow you to bring harm to AdClair or the platform.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Security and Data Privacy
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Information Security</u></strong>. AdClair implements and maintains physical, technical and administrative security measures designed to protect your information from unauthorized access, destruction, use, modification or disclosure.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Data Privacy</u></strong>. AdClair’s Privacy Policy describes how AdClair collects, uses, transfers, discloses and stores your personal data. By creating a AdClair for Agency account, you confirm that you have read, understood and agree to our Privacy Policy in full, and that the Privacy Policy shall be incorporated into these Terms to the extent Personal Data subject to the Applicable Data Laws (as defined in the Privacy Policy) is processed in your use of the Service. In the event of any conflict between these Terms and the Privacy Policy, the Privacy Policy shall prevail.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Teams Administration
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            You can create or join a “Team” on AdClair that allows you to collaborate with others. The creator of the Team is the “Team Owner.” Each Team may have one or more people identified as administrators (each, an “Administrator”) by the Team Owner or another Administrator. Both the Team Owners and Administrators can add, modify or remove people from the Team as well as manage their permissions and access to advertising assets. Only the Team Owner may assign a new Team Owner. If you add a person to a Team, you represent and warrant that you or your organization have obtained all necessary consents from that person to be added.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you are on Team on AdClair for Agencies, the Team Owner or Administrator may control access to, turn off, or turn on ads you create on the account you access as part of the Team. AdClair is not responsible for any actions taken by Team Owners or Administrators. It is your responsibility to not create ads on an account associated with a Team if you do not want to potentially transfer ownership or disclose such ads to others on the Team.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you’re on a Team on AdClair for Agencies, make sure you’re aware of the privacy settings and only create ads you’re comfortable with others on the Team having access to and control over.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Billing
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            AdClair offers a paid Service. You can learn more about AdClair’s various subscription offerings here. Pricing may vary by location and will be based on the billing information you provide us at the time of purchase. If you are on a AdClair for Agencies plan, the Team Owner will be billed for and is responsible for payment of subscription fees.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Subscriptions and Renewals</u></strong>. If you are subscribing to a AdClair plan, you can sign up for either a monthly or annual subscription. Your subscription will automatically renew on a monthly or annual basis as applicable.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Cancellation</u></strong>. You can stop using the Service and/or cancel your subscription at any time via your account settings. If you cancel your subscription you can request and obtain a refund within the first 30-days of subscribing to a AdClair plan.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Refunds</u></strong>. You can request and get a refund for a monthly or yearly subscription within the first 30 days of subscribing to a AdClair plan. The 30-day period is effective from the first day of signing up for a subscription. You will not receive a refund or credit for any amounts if you’re past our 30-day period.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Taxes</u></strong>. Your subscription fees are inclusive of all taxes unless otherwise specified in an agreement with AdClair or on an applicable invoice. Tax rates are calculated based on the billing information you provide and the applicable tax rate at the time of your subscription charge.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Changes to Pricing</u></strong>. AdClair reserves the right to change its prices at any time. If you are on a subscription plan, changes to pricing will not apply until your next renewal or thirty (30) days after notice, whichever is later.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Third Party Services.
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            You may elect to use the Service in conjunction with third-party websites, platforms or apps (including, but not limited to, those available at AdClair.com) (“Third Party Service(s)”). Your use of a Third Party Service is subject to the terms and conditions applicable to that Third Party Service. AdClair makes no representations or warranties in relation to Third Party Services and expressly disclaims all liability arising from your use of Third Party Services.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Within AdClair, you can use apps created by third parties. Those apps might have their own set of terms that apply to you and because the apps were created by third parties, we can’t be responsible for them.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Your Indemnity Obligations
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            You agree, to the extent permitted by law, to defend, indemnify and hold harmless AdClair and its affiliates, officers, directors, agents, licensors and employees from and against any and all claims, costs, damages, losses, liabilities and expenses (including reasonable attorneys’ fees and costs) resulting from or related to (i) your violation of these Terms or (ii) your ads.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If AdClair suffers harm due to your content or your violation of these Terms, or if someone tries to hold AdClair responsible for your content or your violations, you’ll be responsible for any costs incurred by AdClair and defending AdClair.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Limitation of Liability
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            In no event shall either party’s aggregate cumulative liability hereunder (whether in contract, tort, negligence, strict liability in tort or by statute or otherwise) exceed the greater of (i) $100 USD or (ii) the subscription fees paid by you to AdClair during the twelve-month period preceding the event or occurrence giving rise to such liability. The foregoing limitations shall not apply to liabilities arising out of your indemnification obligations or your breach of the section entitled ‘restrictions on use of the service.’
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            In no event shall either party be liable for any consequential, incidental, indirect, special, exemplary or punitive damages, losses, or expenses (including but not limited to business interruption, lost business or lost profits) even if it has been advised of their possible existence and notwithstanding the failure of essential purpose of any remedy. The foregoing limitations shall not apply to liabilities arising out of your indemnification obligations or your breach of the section entitled ‘restrictions on use of the service.’
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            These terms do not affect consumer rights that cannot by law be waived or limited. These terms do not exclude or limit liability arising out of either party’s gross negligence, fraud or willful misconduct.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Term and Termination
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Term</u></strong>. These Terms shall take effect the first time you access the Service and shall continue in full force and effect until i) if you are a paid subscriber, the expiration or termination of your subscription; or ii) if you are using AdClair’s free 7-day trial offering, when your account is deleted or terminated.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Violations</u></strong>. If AdClair, in its sole discretion, determines that you or your use of the Service, your tasks, or your ads violate these Terms, including but not limited to, the Section entitled “Anti-discrimination,” (any of which is considered a “Violation”) AdClair may take one or more of the following actions in its sole discretion: (i) delete the prohibited ads; (ii) suspend your access to the Service; (iii) terminate and delete your account along with all ads associated with that account (iv) permanently ban you from using the Service; and/or (v) disclose the prohibited ads to appropriate government authorities.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you break the rules, we have the right to remove you and everything in your account from the Service.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Effect of Termination</u></strong>. In the event of termination of your subscription for cause due to default by AdClair, AdClair shall refund, on a prorated basis, any prepaid fees for the Service for the period beginning on the effective date of termination through the end of your then-current subscription. In the event of a termination of your subscription to a violation by you, you will not receive any refund and shall immediately pay any outstanding fees for the remaining period of your subscription.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Upon any expiration or termination of your Subscription, you must cease using the Service. You will lose access to your tasks, ads, and any other information uploaded to the Service (and we may delete all such data unless legally prohibited) after expiration or termination of Your Subscription.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Survival of Terms. Sections titled “Term and Termination,” “Billing,” “AdClair’s Intellectual Property,” “Limitation of Liability,” “Indemnification,”and “Miscellaneous” inclusive, shall survive any expiration or termination of these Terms.
          </p>
          <h2 className="font-bold text-[#efefef] font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Miscellaneous
          </h2>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Compliance with Applicable Law</u></strong>. You agree to abide by all applicable local, state, national and foreign laws, treaties and regulations, in connection with your use of the Service. AdClair agrees to abide by all applicable local, state, national and foreign laws, treaties and regulations, in connection with its provision of the Service.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Assignment</u></strong>.You may not assign these Terms or any of your rights under these Terms without AdClair’s consent except to any successor by way of a merger, acquisition, or change of control. AdClair may transfer or assign any of its rights and obligations under these Terms, in whole or in part, at any time with or without notice.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Headings and Explanations</u></strong>. Headings used in these Terms and the explanatory boxes are provided for convenience only and will not in any way affect the meaning or interpretation of the Terms or any portion thereof.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            <strong><u>Severability</u></strong>. If a particular provision of these Terms is found to be invalid or unenforceable, it shall not affect the other provisions and the Terms shall be construed in all respects as if that invalid or unenforceable provision had been limited or omitted to the minimum extent necessary.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Waiver</u></strong>. AdClair’s express waiver or failure to enforce any provision of these Terms shall in no way be construed to be a present or future waiver of such provision nor affect AdClair’s ability to enforce any provision thereafter.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Notices</u></strong>. All required notices to you will be sent to the email address associated with your account or through other legally permissible means.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Changes to these Terms</u></strong>. We may modify these Terms (and any policies or agreements referenced in these Terms) at any time. We will post the most current version of these Terms on AdClair.com. We will provide you with reasonable advance notice of any change to the Terms that, in our sole determination, materially adversely affect your rights or your use of the Service. We may provide you this notice via the Service and/or by email to the email address associated with your account. By continuing to use the Service after any revised Terms become effective, you agree to be bound by the new Terms.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Changes to the Service</u></strong>. AdClair may add, change or remove features or functionality to the Service; modify or introduce limitations to storage or other features; or discontinue the Service altogether at any time. If you are on a paid subscription and AdClair discontinues the Service you are using during your subscription, AdClair will migrate or make available to you a substantially similar service provided by AdClair (if available) and if it’s unable to do so, AdClair will provide you a pro-rata refund of fees prepaid for the remaining period of your subscription.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
          <strong><u>Entire Agreement</u></strong>. These Terms and the terms and policies referenced herein constitute the entire agreement between you and AdClair with respect to the Service. These Terms supersede any prior representations, agreements, or understandings between you and AdClair, whether written or oral, with respect to the Service including previous versions of the Terms. All terms, conditions or provisions on a purchase order shall be of no force and effect notwithstanding any acceptance of such purchase order. The English version of these Terms will control.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
