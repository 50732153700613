import React from "react";
import { Label } from "flowbite-react/components/Label";
import { TextInput } from "flowbite-react/components/TextInput";

const customTheme = {
    "field": {
      "input": {
        "colors": {
          "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-blue focus:ring-blue dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue dark:focus:ring-blue",
          "info": "border-blue bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-blue focus:ring-blue dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-blue dark:focus:ring-blue",
          "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
          "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
          "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
        }
      }
    }
  }


const Input = ({
  onChange,
  bindContext,
  type = "text",
  value,
  placeholder = "",
  label,
  icon,
  addon="",
  error = "",
  disabled,
  required = false,
  color="gray",
  id = "",
  ...props
}) => {
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    if (bindContext) {
      const [{ setItem }, path] = bindContext;
      setItem(path, value.target.value);
    }
  };

  const extra = {

  }
  if(icon){
    extra.icon = icon
  }
  return (
    <div>
      {label && (
        <div className="mb-2 block">
          <Label htmlFor="" value={label} />
        </div>
      )}
      <TextInput
        theme={customTheme} 
        color={error ? "failure" : color}
        id={id}
        type={type}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={handleChange}
        disabled={disabled}
        addon={addon}
        helperText={error}
        {...extra}
      />
    </div>
  );
};

export default Input;
