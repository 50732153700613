import React from "react";
import Myprofile from "./profile/Myprofile";
import Page from "./Page";
import useSubscriptionData from "../hooks/useSubscriptionData";
import { useAppContext } from "../context/Context";
import Loading from "../components/Loading";

const Profile = () => {
  const { subscriptionDetailsLoading } = useAppContext();
  useSubscriptionData();
  return (
    <div className="h-[100vh] w-full ">
      {subscriptionDetailsLoading ? (
        <Loading />
      ) : (
        <Page title="My Profile" subtitle="View and manage your profile information" fullWidth={false}>
          <Myprofile />
        </Page>
        // <div className="flex flex-col min-h-screen bg-gray-100">
        //   <div className="max-w-full ml-16 p-3">
        //     <h1 className="text-xl font-bold py-3"></h1>
        //     <p className="text-sm py-3">
        //       View and manage your profile information
        //     </p>
        //   </div>
        // </div>
      )}
    </div>
  );
};

export default Profile;
