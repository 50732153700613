import React from "react";
import PayPalSubscriptionBtn from "./PayPalSubscriptionBtn";
import { useNavigate } from "react-router";
import api from "../utils/api";
import Icon from "../components/Icon";
import { useAppContext } from "../context/Context";
import { removeToken, removeUser } from "../utils/localStorage";
import useUserPlan from "../hooks/useUserPlan";
import { IoCardOutline } from "react-icons/io5";

const planId =
  process.env.REACT_APP_MODE === "SANDBOX"
    ? process.env.REACT_APP_PAYPAL_SANDBOX_PLAN_ID
    : process.env.REACT_APP_PAYPAL_PLAN_ID;

const PayPalSubscription = () => {
  const { userData } = useAppContext();
  const { setUserPlan } = useAppContext();

  const navigate = useNavigate();

  const { userPlanLoading } = useUserPlan();

  const handleLogout = () => {
    removeToken();
    removeUser();
    navigate("/login");
  };

  const handleContactUs = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    }
  };
  const createSubscription = async (details) => {
    if (!details) return;
    // setSubscriptionLoading(true);
    try {
      const body = {
        planId: details.plan_id,
        subscriptionId: details.id,
        status: details.status,
      };

      const res = await api.post("/createUserPlan", body);
      setUserPlan(res.data.userPlan);
      navigate("/");
    } catch (error) {
      console.log(error);
    }

    // setSubscriptionLoading(false);
  };

  const handleApprove = (data, actions) => {
    return actions.subscription.get().then(async (details) => {
      console.log("Subscription approved: ", details);
      // Handle the subscription details on your server
      await createSubscription(details);
    });
  };

  const handleCancel = (data) => {
    // console.log("Subscription cancelled: ", data);
    // Handle the cancellation on your server
    // alert("Subscription cancelled.");
  };

  const handleError = (error) => {
    console.error("Subscription error: ", error);
    // Handle the error on your server

    alert("An error occurred during the subscription process.");
  };

  if (!planId) {
    return (
      <div>
        <h1>Subscribe to our plan</h1>
        <p>No Plan</p>
      </div>
    );
  }

  const handleStripe = () => {
    const paymentLink = process.env.REACT_APP_STRIPE_PAYMENT_LINK;
    const basicPlan = `${paymentLink}?client_reference_id=${userData._id}&prefilled_email=${userData.email}`;
    window.location.href = basicPlan;
  };

  return (
    <div className="h-[100vh] w-full ">
      <div className=" h-[100vh] w-full ">
        <div className="h-[100vh] w-full">
          <img src="/images/preview.png" className="blur-sm" />
        </div>
        <div className="absolute top-0 right-0 bottom-0 left-0 flex-col flex justify-center items-center inter-300 bg-[#0000002e]">
          {userPlanLoading ? (
            <div className="flex flex-col items-center">
              <div className="w-[100px] rounded">
                <img
                  src="images/logos/logo-black-short.jpeg"
                  className="w-[100px] rounded"
                />
              </div>
              <div>Loading...</div>
            </div>
          ) : (
            <>
              <div className="shadow-lg w-full h-full md:w-[450px] md:h-auto rounded-lg bg-white  p-4">
                {/* <div className="flex justify-center">
                        <img src="images/logo-black-long.png" className="h-[60px]" />
                     </div> */}
                <div className="text-[#2c2e2f] flex flex-col gap-10">
                  <div className=" flex items-center justify-center flex-col font-medium  text-center">
                    <div className="text-[47px]">Early Bird Plan</div>
                    <div className="bg-[#e2f3ff] gap-1 text-[12px] flex border border-[#89ccff] p-2 rounded-full text-[#0072ff] font-medium mx-2">
                      <p>Limited offer | </p>
                      <p className="font-bold">-55%</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    {/*  */}
                    <div className="   mb-4 ">
                      <div className="flex items-baseline leading-4 gap-1">
                        <p className="line-through	text-[38px]  text-[#7b8388]">
                          $49
                        </p>
                        <p className="font-bold	text-[42px]">$19.00</p>
                        <p>/ Month</p>
                      </div>
                      <div className="flex gap-1 text-center text-[#7b8388] ml-2">
                        {" "}
                        + One month free trial
                      </div>
                    </div>

                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Up to $50k ad spend per month</p>
                    </div>
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Agency Ad Account</p>
                    </div>
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Ads Management</p>
                    </div>
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Conversion API</p>
                    </div>
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Profit Calculator</p>
                    </div>
                    {/* <div className="flex gap-2 font-medium ml-2">
                           <Icon name="Check" color='green' />
                           <p>1 Dashboard</p>   
                        </div>
                        <div className="flex gap-2 font-medium ml-2">
                           <Icon name="Check" color='green' />
                           <p>1 User</p>   
                        </div>
                        <div className="flex gap-2 font-medium ml-2">
                           <Icon name="Check" color='green' />
                           <p>1 Workspace</p>   
                        </div> */}
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Standard API Access</p>
                    </div>
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Dedicated Onboarding Team</p>
                    </div>
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Priority Support</p>
                    </div>
                    <div className="flex gap-2 font-medium ml-2">
                      <Icon name="Check" color="green" />
                      <p>Unlimited future updates</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div
                      onClick={handleStripe}
                      className="w-full cursor-pointer rounded bg-blue-700 gap-2 text-white flex items-center justify-center p-3 text-l font-semibold"
                    >
                      <IoCardOutline color="white" size="24" />
                      Start free trial
                    </div>
                    <PayPalSubscriptionBtn
                      planId={planId}
                      handleApprove={handleApprove}
                      handleCancel={handleCancel}
                      handleError={handleError}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2 cursor-pointer">
                <div onClick={handleLogout}>Logout</div>
                <div>|</div>
                <div onClick={handleContactUs}>Contact us</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayPalSubscription;
