import React from 'react'
import { Copy, CheckCircle, Terminal, Globe } from 'lucide-react';


const ThankYouIntegration = () => {
    return (
        <div className="w-full max-w-4xl mx-auto p-8">
            <div className="text-center space-y-6">
                <div className="flex justify-center">
                    <div className="bg-green-100 rounded-full p-3">
                        <CheckCircle className="w-12 h-12 text-green-600" />
                    </div>
                </div>

                <div className="space-y-2">
                    <h1 className="text-3xl font-bold text-gray-900">
                        Thank you for integrating with us!
                    </h1>
                    <p className="text-lg text-gray-600">
                        Your website is now successfully connected to our platform
                    </p>
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mt-8 max-w-xl mx-auto">
                    <h2 className="text-xl font-semibold text-gray-900 mb-4">
                        What's Next?
                    </h2>
                    <ul className="space-y-4 text-left">
                        <li className="flex items-start">
                            <div className="flex-shrink-0 mt-1">
                                <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center">
                                    <span className="text-blue-600 text-sm font-medium bg-green-500">1</span>
                                </div>
                            </div>
            
                            <p className="ml-4 text-gray-600">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </li>
                        <li className="flex items-start">
                            <div className="flex-shrink-0 mt-1">
                                <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center">
                                    <span className="text-blue-600 text-sm font-medium">2</span>
                                </div>
                            </div>
                            <p className="ml-4 text-gray-600">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </li>
                        <li className="flex items-start">
                            <div className="flex-shrink-0 mt-1">
                                <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center">
                                    <span className="text-blue-600 text-sm font-medium">3</span>
                                </div>
                            </div>
                            <p className="ml-4 text-gray-600">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </li>
                    </ul>
                </div>
                <p className="text-sm text-gray-500 pt-6">
                    Need help? Our support team is always here to assist you
                </p>
            </div>
        </div>
    );
};

export default ThankYouIntegration
