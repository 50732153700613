import React, { useEffect, useState } from "react";
import Switcher from "./Switcher";
import { useTableContext } from "../../context/TableContext";
import Icon from "../Icon";
import { useAppContext } from "../../context/Context";

const TableData = ({ data }) => {
  const { assignProducts, setAssignProducts } = useAppContext();
  const {
    selectedItems,
    tableConfiguration: configuration,
    selectItem,
    selectable,
    setSelectedItem,
    onSwitch
  } = useTableContext();
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    setIsSelected(!!selectedItems.find(({ id }) => id === data.id));
  }, [selectedItems]);

  return (
    <tr
      className={`tableRow hover:bg-[#EDF5FF] cursor-pointer transition ${
        isSelected ? "bg-[#EDF5FF]" : ""
      }`}
      key={data.id}
    >
      {selectable && (
        <td className=" w-20 ">
          <input
            type="checkbox"
            className="w-full h-4 m-[2px]"
            checked={isSelected}
            onChange={() => {
              selectItem(data);
              // setAssignProducts([...assignProducts, data])
            }}
          />
        </td>
      )}

      {configuration.map((cell, index) => {
        switch (cell.type) {
          case "status":
            return <StatusCell key={index} {...{ data, ...cell, index }} />;
          case "html":
            return <ImageText key={index}  {...{ data, setSelectedItem, ...cell, index }} />;
          case "switch":
            return <SwitchCell key={index}  {...{ data, ...cell, onSwitch, index }} />;
          case "text":
            return <TextCell key={index}  {...{ data, ...cell , index}} />;
          case "actions":
            return <ActionsCell key={index}  {...{ data, ...cell, index }} />;
        }
      })}
    </tr>
  );
};

const StatusCell = ({ data, value , index}) => {
  let color = "#CAD1DB";
  const test = false;
  if (["ready", "active", "accepted"].indexOf(data[value]) !== -1) {
    color = "#34B86D";
  } else if (["pending", "warning", "inactive"].indexOf(data[value]) !== -1) {
    color = "#FFA800";
  } else if (["blocked", "error", "removed"].indexOf(data[value]) !== -1) {
    color = "#F5432C";
  }
  return (
    <td className="capitalize font-semibold" key={index}>
      <div className="flex items-center ">
        <div
          className={`h-2 w-2 rounded-lg bg-[${color}] mx-2 ${
            test && "bg-[#34B86D] bg-[#FFA800] bg-[#F5432C] "
          }`}
        ></div>
        <div
          className={`text-[${color}]  ${
            test && "text-[#34B86D] text-[#FFA800] text-[#F5432C] "
          }`}
        >
          {" "}
          {data[value]}
        </div>
      </div>
    </td>
  );
};

const ImageText = ({ data, value, setSelectedItem, index }) => {
  const { assignProducts, setAssignProducts } = useAppContext();
  const { setCampaignData } = useTableContext();

  return (
    <td
      className="flex items-center gap-2"
      onClick={() => {
        setCampaignData(data);
        if (assignProducts && assignProducts.length > 0) {
          return;
        }
        setSelectedItem(data);
      }} key={index}
    >
      <div className="w-8 h-8 flex items-center justify-center">
        {
          typeof value[0] === "function" ? 
            <img className="rounded" src={value[0](data)} alt="" />
            :
              data[value[0]] ? (
                <img className="rounded" src={data[value[0]]} alt="" />
              ) : (
                <Icon name="Image" color={"#CAD1DB"} size="36" />
              )
        }
      </div>
      {data[value[1]]}
    </td>
  );
};

const SwitchCell = ({ data, value, onSwitch, index}) => {
  return (
    <td  key={index}>
      <Switcher state={data[value]} onChange={() => onSwitch(data._id)} />
    </td>
  );
};

const TextCell = ({ data, value, link, index }) => {
  const handleClick = () => {
    if (link && data[link]) window.open(data[link], "_blank");
  };
  return (
    <td
      className={
        link && data[link] ? "cursor-pointer underline underline-offset-1 " : ""
      }
      key={index}
    >
      <div className="flex items-center" onClick={handleClick}>
          {
            typeof value !== "function" ? 
               data[value]
          :
            value(data)
        }
        {link && data[link] ? (
          <Icon name="ExternalLink" size="14" />
        ) : undefined}
      </div>
    </td>
  );
};

const ActionsCell = ({index}) => {
  return (
    <td  key={index}>
      <div className="mx-4 flex justify-left">
        <Icon name="MoreHorizontal" />
      </div>
    </td>
  );
};

export default TableData;
