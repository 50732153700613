import React from "react";
import { Icon } from "../../components";
import { useCampaignContext } from "../../context/CampaignContext";
import Button from "../../components/Button";

const ConversionGoal = ({ setStep, step }) => {
  const newCampaignContext = useCampaignContext();
  const {
    state: { settings },
    setItem,
  } = newCampaignContext;

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <div className="flex gap-4  flex-col items-center ">
      <div className="text-xl font-bold mb-4">
        Select the conversion for your goal
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <Option
          icon={"ShoppingCart"}
          title={"Add to cart"}
          subtitle={"Online Sales"}
          onClick={() => setItem("settings.conversion_goal", "ADD_TO_CART")}
          goal={"ADD_TO_CART"}
          selectedGoal={settings.conversion_goal}
        />
        <Option
          icon={"Heart"}
          title={"Add to whishlist"}
          subtitle={"Online Sales"}
          onClick={() => setItem("settings.conversion_goal", "ADD_TO_WISHLIST")}
          goal={"ADD_TO_WISHLIST"}
          selectedGoal={settings.conversion_goal}
        />
        <Option
          icon={"CreditCard"}
          title={"Initiated checkout"}
          subtitle={"Online Sales"}
          onClick={() =>
            setItem("settings.conversion_goal", "INITIATED_CHECKOUT")
          }
          goal={"INITIATED_CHECKOUT"}
          selectedGoal={settings.conversion_goal}
        />
        <Option
          icon={"Tags"}
          title={"Purchase"}
          subtitle={"Online Sales"}
          onClick={() => setItem("settings.conversion_goal", "PURCHASE")}
          goal={"PURCHASE"}
          selectedGoal={settings.conversion_goal}
        />
      </div>
      <div className="flex items-center gap-2 bg-black-100 p-2 shadow rounded text-sm font-bold text-blue-100 w-full">
        <Icon name={"Info"} size={18} color={"#f8fafc"} />
        Custom Events to answer all your needs comming soon.
      </div>
      <div className="flex items-center justify-between w-full">
        <Button onClick={handleBack} type="secondary" value={"Back"} />
        <Button
          onClick={handleNext}
          type="primary"
          disabled={!settings.conversion_goal}
          value={"Next"}
        />
      </div>
    </div>
  );
};

const Option = ({
  icon,
  title,
  indicator,
  onClick,
  goal,
  selectedGoal,
  subtitle,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${"flex-1  cursor-pointer hover:border-blue hover:shadow"} 
        ${selectedGoal === goal ? "border-blue shadow bg-blue-200" : "bg-white"}
      
      flex  flex-col gap-4 p-4 border-2  transition rounded-lg w-full`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Icon name={icon} size={32} color={"#697586"} />
          <div className="ml-4">
            <h2 className="text-lg font-bold">{title}</h2>
          </div>
        </div>
        {indicator && (
          <div className="flex items-center gap-2 bg-blue-100 p-2 rounded text-sm font-bold text-black-100">
            <Icon name={"Info"} size={18} color={"#697586"} />
            {indicator}
          </div>
        )}
      </div>
      <div className="text-md font-semibold text-black-100">{subtitle}</div>
    </div>
  );
};

export default ConversionGoal;
