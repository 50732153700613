import React, { useState } from "react";
import Page from "../Page";
import { useNavigate } from "react-router";
import Details from "./Details";
import Goal from "./Goal";
import Connect from "./Connect";
import ConversionGoal from "./ConversionGoal";
import { useCampaignContext } from "../../context/CampaignContext";

const CreateCampaign = () => {
  const navigate = useNavigate();
  const newCampaignContext = useCampaignContext();
  const {
    state: { settings },
  } = newCampaignContext;
  const [step, setStep] = useState(1)
  const actions = [
    {
      name: "Cancel",
      action: () => navigate("/campaigns"),
      type: "danger",
      icon: {
        name: "Undo2", // GrUndo
      },
    },
  ];

  return (
    <Page title={`Create new campaign ${settings?.product?.title && step == 4 ? "- "+ settings?.product?.title : ""}`} actions={actions} fullWidth={step === 4}>
        {/* <div className="flex gap-2 justify-center ">
            <div onClick={() => setStep(1)}>Stepp 1</div>
            <div onClick={() => setStep(2)}>Stepp 2</div>
            <div onClick={() => setStep(3)}>Stepp 3</div>
            <div onClick={() => setStep(4)}>Stepp 4</div>
        </div> */}
        
        {
            step == 1 && <Goal {...{step, setStep}} />
        }
        {
            step == 2 &&  <ConversionGoal {...{step, setStep}} />
        }
        {
            step == 3 &&  <Connect {...{step, setStep}} />
        }
        {
            step == 4 &&  <Details {...{step, setStep}} />
        }
    </Page>
  );
};

export default CreateCampaign;
