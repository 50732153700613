import React, { useState } from 'react'
import HeroImg from '../components/HeroImg'
import Button from '../components/Button'
import OtpInput from "react-otp-input";
import api from '../utils/api';
import { useLocation, useNavigate } from 'react-router';
import { useAppContext } from '../context/Context';

const inputstyle = {
    width: "40px",
    height: "56px",
    background: "#fff",
    borderRadius: "5px",
    border: "1px solid black",
    fontSize: "20px",
    margin: "16px 18px 20px 18px",
    outline: "none",
    color: "black",
};

const VerifyEmail = () => {

    const navigate = useNavigate();
    const { userData, setUserData } = useAppContext()
    const location = useLocation();
    const email = location?.state?.email || null;

    if (!email) {
        navigate("/profile")
    }

    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!otp || !email) return;

        setIsLoading(true);

        try {
            await api.post("/verify-email-otp", {
                email: email,
                otp: otp
            });

            setUserData({ ...userData, emailVerified: true })
            navigate("/profile");

        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    return (
        <div className="h-screen flex">
            <HeroImg />
            <div className="flex-1 h-full bg-[#fff] flex-col flex justify-center items-center  ">
                <div className="h-full flex justify-center items-center flex-col">
                    <div className="w-[400px] bg-[#fff] rounded-[5px] flex gap-3  px-[40px]  ">
                        <div className=" flex flex-col gap-5 flex-1">
                            <div className="flex flex-col gap-1 items-center">
                                <h1 className="text-[20px] font-medium text-center text-[#0a1218] leading-[52px]">
                                    Verify email
                                </h1>
                                <p className='text-center'>An otp is sent on email <br></br><span className='underline'>{email}</span></p>
                            </div>
                            <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                                <div className="flex flex-col gap-4">
                                    <div className="flex justify-center items-center">
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            inputStyle={inputstyle}
                                            renderSeparator={<span className="text-light-primary">-</span>}
                                            renderInput={(props) => <input {...props} />}
                                            shouldAutoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3">
                                    <Button
                                        disabled={isLoading}
                                        value={isLoading ? "Loading..." : "Submit"}
                                        className="w-full font-medium text-[16px] leading-6 h-14 "
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail