import React, { useState } from 'react';
import useNews from '../hooks/useNews';
import Icon from './Icon';
import NewsModal from './modals/NewsModal';

const NewsBtn = ({ expanded }) => {
    const [showModal, setShowModal] = useState(false);
    const { news } = useNews();
    return (
        <>
            <button
                onClick={() => setShowModal(true)}
                className={`flex cursor-pointer items-center mx-auto bg-white rounded-md transition-all duration-300 ${expanded ? "w-[90%] p-2 my-2 border border-[#808080]/30" : "w-fit ml-4 mb-2"}`}
            >
                <div className={`flex items-center justify-center rounded-lg ${expanded ? "w-10 h-10" : "w-8 h-8"}`}>
                    <Icon
                        name={"Newspaper"}
                        color={"#697586"}
                        size={24}
                    />
                </div>
                {expanded && (
                    <div className="ml-4">
                        <h2 className="font-semibold text-gray-800 text-sm text-start">News</h2>
                        <p className="text-xs text-gray-500">Click to view</p>
                    </div>
                )}
            </button>

            <NewsModal showModal={showModal} setShowModal={setShowModal} news={news} />
        </>
    );
}

export default NewsBtn;
