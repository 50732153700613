import React, { useState, useEffect} from "react";
import { Button } from "flowbite-react/components/Button";
import { HiOutlineViewColumns } from "react-icons/hi2";
import { BiCaretDown } from "react-icons/bi";
import FlowPopover from "../flowbite/Popover";
import FlowCheckbox from "../flowbite/Checkbox";
import Icon from "../Icon";

const DashboardFilter = ({
  columnFilters, 
  setColumnFilters,
  columnMetrics, 
  setMolumnMetrics,
  selectedCampaign,
  selectedAdAccount
}) => {

  const [untrackedAdsIds, setUntrackedAdsIds] = useState([])
  const [untrackedCampaignsIds, setUntrackedCampaignsIds] = useState([])
  const checkTracking = (url) => {
    const regex1 = /adcl_id={{ad.id}}/;
    const regex2 = /adcl_s=facebook/;
    return url && regex1.test(url) && regex2.test(url);
  }

  const [_filters, setFilters] = useState(columnFilters);
  const handleChange = (key, value) => {
    const _cols = { ..._filters };
    _cols[key].value = value;
    setColumnFilters(_cols);
    setFilters(_cols);
  }

  const handleArrayChange = (value, index) => {
    const _metrics = [...columnMetrics];
    _metrics[index].value = value;
    setMolumnMetrics(_metrics);
  }

  const goToManager = () => {
    const act= selectedAdAccount?.id
    const link = `https://adsmanager.facebook.com/adsmanager/manage/ads/edit/standalone?act=${act}&nav_entry_point=am_local_scope_selector&selected_campaign_ids=${untrackedCampaignsIds.join(",")}&selected_ad_ids=${untrackedAdsIds.join(",")}&breakdown_regrouping=true&nav_source=no_referrer&current_step=0`
    window.open(link, "_blank");
  }

  useEffect(() => {
    const campaigns = [] 
    let ads = []  
   
    selectedCampaign.campaigns.forEach(campaign => {
      campaign.ads.data.forEach(ad => {
        if(!checkTracking(ad.creative?.url_tags)){ 
          if(ads.indexOf(ad.id) === -1){
            ads.push(ad.id)
          }
          if(campaigns.indexOf(campaign.id) === -1){
            campaigns.push(campaign.id)
          }
        }
      })
    })
    selectedCampaign.adSets.forEach(adset => {
      adset.ads.data.forEach(ad => {
        if(!checkTracking(ad.creative?.url_tags)){ 
          if(ads.indexOf(ad.id) === -1){
            ads.push(ad.id)
          }
        }
      })
    })

    selectedCampaign.ads.forEach(ad => {
        if(!checkTracking(ad.creative?.url_tags)){ 
          if(ads.indexOf(ad.id) === -1){
            ads.push(ad.id)
          }
        }
    })

    setUntrackedAdsIds(ads)
    setUntrackedCampaignsIds(campaigns)
  },[selectedCampaign])

  return (
    <div className="flex justify-end m-2 items-center gap-2 ">
      <Button color="gray" onClick={goToManager} disabled={untrackedAdsIds.length == 0}>
        <div className="flex gap-2 items-center">
          Add tracker 
          {/* {untrackedAdsIds.length > 0 && <span className="bg-">{untrackedAdsIds.length}</span>} */}
          <Icon name="ExternalLink" size={14} />
        </div>
      </Button>
      <FlowPopover
        aria-labelledby="area-popover" 
        arrow={false}
        content={
          <div className="flex w-64 flex-col gap-4 p-4 text-sm text-gray-500 dark:text-gray-400"> 
            <div>Columns</div>
            {Object.keys(_filters).map((key, index) => (
              <FlowCheckbox
                key={columnFilters[key].code}
                label={columnFilters[key].name}
                id={columnFilters[key].code}
                value={columnFilters[key].value}
                onChange={(value) => handleChange(key,value)}
              />
            ))}
             <div>Metrics</div>
             {columnMetrics.map((metric, index) => (
              <FlowCheckbox
                key={metric.code}
                label={metric.name}
                id={metric.code}
                value={metric.value}
                onChange={(value) => handleArrayChange(value, index)}
              />
            ))}
          </div>
        }
        button={
          <Button color="gray">
            <div className="flex gap-2 items-center">
            <HiOutlineViewColumns />
            Columns
            <BiCaretDown className="ml-2" /></div>
          </Button>
        }
      />
    </div>
  );
};
export default DashboardFilter;
