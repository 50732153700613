import React, { useEffect, useState, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Switcher from "./Switcher";
import { DateRangePicker } from "react-date-range";
import DashboardFilter from "./DashboardFilters";
import Icon from "../Icon";
import Button from "../Button";
import Dropdown from "../Dropdown";
import { moneyFormat } from "../../utils/helper";
import { Search } from "lucide-react";
import { useCampaignsContext } from "../../context/CampaignsContext";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Input from "../Input";
import { formatDateToDDMMYYYY } from "../../utils/helper";
import { Tooltip } from "react-tooltip";
import { CiCalendar } from "react-icons/ci";
import {
  FILTERS,
  FILTERS_OBJECT,
  METRICS_ARRAY,
} from "../../constant/constant";
import { FaMeta } from "react-icons/fa6";

const DashboardTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [spentTotal, setSpentTotal] = useState("-");
  const [purchaseTotal, setPurchaseTotal] = useState("-");
  const [adpixelOrdersTotal, setAdpixelOrdersTotal] = useState("-");
  const [profitTotal, setProfitTotal] = useState("-");

  const [columnFilters, setColumnFilters] = useState(FILTERS_OBJECT);
  const [columnMetrics, setMolumnMetrics] = useState(METRICS_ARRAY);

  const {
    selectedItems,
    setSelectedItems,
    selectedAdLevel,
    setSelectedAdLevel,
    selectedCampaign,
    setSelectedCampaign,
    adAccountsState,
    selectedAdAccount,
    campaignsState,
    ordersState,
    setSelectedAdAccount,
    selectedRange,
    setSelectedRange,
    onSwitch,
    setFilters,
    untrackedAdsIds, setUntrackedAdsIds
  } = useCampaignsContext();

  const checkTracking = (url) => {
    const regex1 = /adcl_id={{ad.id}}/;
    const regex2 = /adcl_s=facebook/;
    return url && regex1.test(url) && regex2.test(url);
  }
  const switcherBodyTemplate = (rowData) => {
    const isActive = rowData.status === "ACTIVE";
    return (
      <div className="flex items-center justify-center">
        <Switcher
          state={isActive}
          onChange={() => onSwitch(rowData.id, isActive)}
        />
      </div>
    );
  };

  const NameBodyTemplate = ({rowData}) => {
      let _untrackedAdsIds = null;
      if (selectedAdLevel === 0 || selectedAdLevel === 1) {
        _untrackedAdsIds = rowData?.ads?.data?.every(ad => {
          const url = ad.creative.url_tags;
          return checkTracking(url);
        });
      } else {
          const url = rowData?.creative?.url_tags;
          _untrackedAdsIds = checkTracking(url);
      }

    const handleClick = () => {
      setSelectedItems([rowData]);
      if (selectedAdLevel === 0) {
        setSelectedCampaign({ campaigns: [rowData], adSets: [], ads: [] });
        setSelectedAdLevel(1);
      } else if (selectedAdLevel === 1) {
        setSelectedCampaign({ ...selectedCampaign, adSets: [rowData] });
        setSelectedAdLevel(2);
      }
    };

    return (
      <>
        <Tooltip
          id={"tooltip-name-" + rowData.id}
          className="tooltip-content"
          opacity="1"
        >
          <div className="flex flex-col gap-4">{rowData.name}</div>
        </Tooltip>
        <div>
          <div className="flex items-center gap-2">
            {/* <div>
              <FaMeta color="#0072ff" />
            </div> */}
            {
              _untrackedAdsIds != true && (
                <div className="flex items-center gap-2">
                  <Icon name="AlertTriangle" size={12} color="#FFA800" />
                  <div className="text-[#FFA800] text-[10px] font-mediumd">Missing tracking params</div>
                </div>
            )}
          </div>
          <div
            data-column="name"
            onClick={handleClick}
            data-tooltip-id={"tooltip-name-" + rowData.id}
            data-tooltip-place="right"
            data-tooltip-variant="light"
            data-tooltip-position-strategy="fixed"
            className="text-[#187fff] hover:underline  underline-offset-1 transition ellipsis w-[300px] "
          >
           
            {rowData.name}
          </div>
        </div>
      </>
    );
  };

  const budgetBodyTemplate = ({ daily_budget, lifetime_budget }) => {
    let element = "";
    if (lifetime_budget) {
      element = moneyFormat(lifetime_budget, "$") + " (Life time) ";
    } else if (daily_budget) {
      element = moneyFormat(daily_budget, "$") + " (Daily) ";
    } else {
      element = "Using ad set budget";
    }
    return (
      <div className="font-medium justify-end flex text-right">{element} </div>
    );
  };

  const spentBodyTemplate = (rowData) => {
    const spent = rowData?.insights?.spend;
    return (
      <div className="font-medium justify-end flex text-right">
        {spent ? "$" + spent : "-"}{" "}
      </div>
    );
  };

  const metricBodyTemplate = (rowData, code, prefix = "") => {
    let value = parseFloat(rowData?.insights[code] || 0);
    if (!isNaN(value) && Number.isFinite(value)) {
      value = parseFloat(value.toFixed(3));
    }
    return (
      <div className="font-medium justify-end flex text-right">
        {value ? prefix + value : "-"}{" "}
      </div>
    );
  };

  const metricFooterTemplate = ({ code, prefix = "" }) => {
    let value = campaignsState?.data.reduce((acc, rowData) => {
      return acc + parseFloat(rowData.insights[code] || 0);
    }, 0);

    if (!isNaN(value) && Number.isInteger(value)) {
      value = value;
    } else if (!isNaN(value) && Number.isFinite(value)) {
      value = parseFloat(value.toFixed(3));
    }

    return (
      <div className={`font-bold  flex justify-end`}>
        {value ? prefix + value : "-"}{" "}
      </div>
    );
  };
  const conversionBodyTemplate = (rowData) => {
    const objective = rowData.objective;
    const actions = rowData?.insights?.actions;
    let result = "-";
    let resultType = "";
    if (actions && objective) {
      if (objective === "LINK_CLICKS") {
        result =
          actions.find((action) => action.action_type === "link_click")
            ?.value || 0;
        resultType = "Link clicks";
      }
      if (objective === "OUTCOME_SALES") {
        result =
          actions.find(
            ({ action_type }) =>
              action_type === "omni_purchase" ||
              action_type === "offsite_conversion.fb_pixel_purchase"
          )?.value || 0;
        resultType = "Purchases";
      }
    }
    return (
      <>
        <Tooltip
          id={"tooltip" + rowData.id}
          className="tooltip-content"
          opacity="1"
        >
          <div className="flex flex-col gap-4">
            <h3>All conversions</h3>
            <div className="flex flex-col">
              {rowData?.insights?.actions &&
                rowData?.insights?.actions
                  .filter(
                    ({ action_type }) => action_type.indexOf("fb_pixel_") !== -1
                  )
                  .map((action, index) => {
                    return (
                      <div
                        key={index}
                        className="capitalize flex justify-between items-center min-w-44"
                      >
                        <div className="font-medium">
                          {action.action_type
                            .split("fb_pixel_")[1]
                            .split("_")
                            .join(" ")}
                          :
                        </div>
                        <div> {action.value}</div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </Tooltip>
        <div
          className="font-medium justify-end flex text-right"
          data-tooltip-id={"tooltip" + rowData.id}
          data-tooltip-place="top"
          data-tooltip-variant="light"
          data-tooltip-position-strategy="fixed"
        >
          <div className="flex flex-col items-end ">
            <div className="flex gap-2 items-center justify-end">
              {result} <Icon name="Info" size={12} />
            </div>
            {resultType && (
              <div className="text-[12px] text-[#1c2b33a6]">{resultType}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const profitBodyTemplate = (rowData) => {
    const campaignOrdersData = ordersState?.data[rowData.id];

    const isPositive = true; //parseInt(Math.random()*10) % 2 //rowData.netProfit >= 0
    return campaignOrdersData ? (
      <div
        className={`${
          isPositive
            ? " text-[#34B86D] bg-[#e0ffee]"
            : " text-[#d74100] bg-[#fdf5f2]"
        } flex rounded gap-2 p-2 items-center justify-center`}
      >
        <Icon name={isPositive ? "TrendingUp" : "TrendingDown"} />
        <div>{moneyFormat(campaignOrdersData.totalGrossProfit * 100, "$")}</div>
      </div>
    ) : (
      <div className={` flex rounded gap-2 p-2 items-center justify-center`}>
        <Icon name="CircleSlash" color={"#FFA800"} size={18} />
        <div>No data</div>
      </div>
    );
  };

  const ordersBodyTemplate = (rowData) => {
    const campaignOrdersData = ordersState?.data[rowData.id];
    return campaignOrdersData ? (
      <div>
        <div className="text-center">{campaignOrdersData.count}</div>
      </div>
    ) : (
      <div className={` flex rounded gap-2 p-2 items-center justify-center`}>
        <Icon name="CircleSlash" color={"#FFA800"} size={18} />
        <div>No data</div>
      </div>
    );
  };

  const deliveryBodyTemplate = (rowData) => {
    let color = "#CAD1DB";
    if (
      ["ready", "active", "accepted"].indexOf(rowData.status.toLowerCase()) !==
      -1
    ) {
      color = "#34B86D";
    }
    return (
      <div className="flex items-center font-medium ">
        <div className={`h-2 w-2 rounded-lg bg-[${color}] mx-2`}></div>
        <span className="capitalize">{rowData.status}</span>
      </div>
    );
  };

  useEffect(() => {
    setFilters({ searchTerm });
  }, [searchTerm]);

  useEffect(() => {
    if (campaignsState?.data) {
      const tmp = campaignsState?.data.reduce((acc, rowData) => {
        return acc + parseFloat(rowData.insights?.spend || 0);
      }, 0);
      setSpentTotal(tmp.toFixed(2));
    }
  }, [campaignsState?.data]);

  useEffect(() => {
    if (ordersState?.data) {
      const _tmpOrders = Object.values(ordersState?.data).reduce(
        (acc, { count }) => acc + count,
        0
      );
      setAdpixelOrdersTotal(_tmpOrders);

      const _mpProfit = Object.values(ordersState?.data).reduce(
        (acc, { totalGrossProfit }) => acc + totalGrossProfit,
        0
      );
      setProfitTotal(_mpProfit.toFixed(2));
    }
  }, [ordersState?.data]);

  const renderFooter = ({ value, style = "", prefix = "" }) => {
    return (
      <div className={`font-bold  flex ${style}`}>
        {value ? prefix + value : "-"}{" "}
      </div>
    );
  };

  return (
    <>
      <AdAccountSelector
        {...{
          adAccountsState,
          selectedAdAccount,
          setSelectedAdAccount,
          searchTerm,
          setSearchTerm,
          selectedRange,
          setSelectedRange,
          selectedAdAccount,
          setSelectedAdAccount,
        }}
      />
      <div className="dashboard-table-div font-bold">
        {/* tabs */}
        <Tabs
          {...{
            selectedAdLevel,
            setSelectedAdLevel,
            selectedItems,
            setSelectedItems,
            selectedCampaign,
            setSelectedCampaign,
          }}
        />
        <div
          className={`border rounded-tl-none  bg-white  mx-auto p-[20px] rounded-xl `}
        >
          <div>
            <DashboardFilter
              {...{
                selectedAdAccount,
                selectedCampaign,
                columnFilters,
                setColumnFilters,
                columnMetrics,
                setMolumnMetrics,
              }}
            />
          </div>
          <div className="rounded shadow">
            <DataTable
              value={campaignsState?.data}
              loading={campaignsState?.loading}
              stripedRows
              scrollable
              showGridlines
              scrollHeight="60vh"
              selection={selectedItems}
              removableSort
              onSelectionChange={(e) => {
                if (e.originalEvent?.target?.dataset?.column === "name") return;
                setSelectedItems(e.value);
              }}
              emptyMessage="No available data"
              sortMode="multiple"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                frozen
                alignFrozen="left"
              ></Column>
              <Column
                field="status"
                body={switcherBodyTemplate}
                header="On/Off"
                columnKey="switch"
                frozen
                alignFrozen="left"
              />
              <Column
                field="campaignName"
                body={(data) => <NameBodyTemplate rowData={data} />}
                header="Campaign Name"
                style={{ width: "300px" }}
                frozen
                alignFrozen="left"
                className="font-bold"
                footer="-"
              />
              <Column
                field="daily_budget"
                header="Budget"
                body={budgetBodyTemplate}
                sortable
              />
              <Column
                header="Amount Spent"
                className="font-bold"
                field="insights.spend"
                sortable
                body={spentBodyTemplate}
                footer={() =>
                  renderFooter({
                    value: spentTotal,
                    prefix: "$",
                    style: "justify-end",
                  })
                }
              />
              <Column
                field="purchase"
                header="Results"
                sortable
                className="font-bold"
                body={conversionBodyTemplate}
              />
              <Column
                field="pixelorders"
                header="AdPixel orders"
                body={ordersBodyTemplate}
                style={{ minWidth: "150px" }}
                sortable
                className="font-bold"
                footer={() =>
                  renderFooter({
                    value: adpixelOrdersTotal,
                    style: "justify-center",
                  })
                }
              />
              <Column
                field="netProfit"
                header="Net Profit"
                body={profitBodyTemplate}
                style={{ minWidth: "150px" }}
                sortable
                className="font-bold"
                footer={() =>
                  renderFooter({
                    value: profitTotal,
                    style: "justify-center",
                    prefix: "$",
                  })
                }
              />
              {columnMetrics
                .filter((metric) => metric.value)
                .map((metric) => {
                  return (
                    <Column
                      key={metric.code}
                      header={metric.name || ""}
                      field={`insights.${metric.code}`}
                      className="font-bold"
                      sortable
                      body={(rowData) =>
                        metricBodyTemplate(rowData, metric.code, metric.prefix)
                      }
                      footer={() => metricFooterTemplate({ code: metric.code })}
                    />
                  );
                })}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

const AdAccountSelector = ({
  adAccountsState,
  selectedAdAccount,
  setSelectedAdAccount,
  searchTerm,
  setSearchTerm,
  selectedRange,
  setSelectedRange,
}) => {
  const [localSelectedRange, setLocalSelectedRange] = useState([
    selectedRange[0],
  ]);
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
  const [calendarVisibility, setCalendarVisibility] = useState(false);
  const handleCalendarVisibility = () => {
    setCalendarVisibility(!calendarVisibility);
  };

  const onUpdate = () => {
    setSelectedRange(localSelectedRange);
    setCalendarVisibility(false);
  };

  const oncancel = () => {
    setLocalSelectedRange(selectedRange);
    setCalendarVisibility(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearch = () => {
    setSearchTerm(localSearchTerm);
  };
  useEffect(() => {
    setLocalSelectedRange(selectedRange);
  }, [selectedRange]);

  return (
    <div className="flex justify-between sm:flex-row flex-col gap-2">
      <div className="flex gap-4 w-full w-full md:w-1/2 sm:flex-row flex-col">
        <div className="sm:w-[40%] sm:max-w-[250px] w-full ">
          <Dropdown
            isLoading={adAccountsState.loading}
            name="Ad account"
            placeholder="Select ad account"
            value={selectedAdAccount}
            options={adAccountsState.data}
            onChange={setSelectedAdAccount}
            isClearable={false}
            withImage="image"
          />
        </div>

        <div className="px-2 h-10 justify-center bg-white border border-[#cad1db] rounded-lg flex items-center gap-2 sm:w-[60%] w-full transition-all focus-within:border-[#0D6EFD]">
          <Search style={{ color: "#63657E", width: "18px" }} />
          <input
            type="text"
            className="w-full text-[#63657E] text[14px] h-full outline-none border-none "
            placeholder="search here"
            value={localSearchTerm}
            onChange={(e) => setLocalSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button
            value="Search"
            type="secondary"
            onClick={() => setSearchTerm(handleSearch)}
          />
        </div>
      </div>
      <div className="relative cursor-pointer w-full md:w-[275px]">
        <div onClick={handleCalendarVisibility}>
          <Input
            value={`${formatDateToDDMMYYYY(
              selectedRange[0].startDate
            )} - ${formatDateToDDMMYYYY(selectedRange[0].endDate)}`}
            icon={CiCalendar}
          />
        </div>
        {calendarVisibility && (
          <>
            <div
              className="fixed inset-0 bg-black bg-opacity-20 z-10 flex duration-500 justify-center items-center"
              onClick={oncancel}
            ></div>
            <div className="absolute top-11 right-0 z100 shadow-xl rounded">
              <DateRangePicker
                onChange={(item) => setLocalSelectedRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={localSelectedRange}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                maxDate={new Date()}
              />
              <div className="absolute bottom-2 left-2 flex gap-2">
                <Button
                  className="w-full font-medium text-[16px] leading-6 h-14 "
                  value="Update"
                  onClick={onUpdate}
                />
                <Button value="Cancel" type="secondary" onClick={oncancel} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Tabs = ({
  selectedAdLevel,
  setSelectedAdLevel,
  selectedItems,
  setSelectedItems,
  selectedCampaign,
  setSelectedCampaign,
}) => {
  const tabsSettings = [
    {
      name: "Campaign",
      icon: "Folder",
    },
    {
      name: "Ad Sets",
      icon: "LayoutGrid",
    },
    {
      name: "Ads",
      icon: "PanelTop",
    },
  ];

  const handleRemove = (index) => {
    setSelectedItems([]);
    setSelectedCampaign((prev) => {
      if (selectedAdLevel === 0) {
        return { ...prev, campaigns: [] };
      } else if (selectedAdLevel === 1) {
        return { ...prev, adSets: [] };
      } else if (selectedAdLevel === 2) {
        return { ...prev, ads: [] };
      }
      return prev;
    });
  };

  return (
    <div className="flex gap-4 mt-[15px] grid grid-cols-4 gap-2">
      {tabsSettings.map(({ name, icon }, index) => {
        const selectedLength =
          index === 0
            ? selectedCampaign?.campaigns?.length
            : index === 1
            ? selectedCampaign?.adSets?.length
            : selectedCampaign?.ads?.length;

        return (
          <div
            className="relative "
            onClick={() => setSelectedAdLevel(index)}
            key={index}
          >
            <div
              className={`flex items-center cursor-pointer justify-between rounded-lg gap-3 border border-[#E2E8F0] font-normal rounded-b-none border-b-0 ${
                selectedAdLevel === index
                  ? "text-white bg-blue"
                  : "text-[#63657E] bg-white"
              } px-3 py-2`}
            >
              <div className="flex">
                <Icon name={icon} className="mr-2" />
                <label className="flex-grow align-middle hidden md:block">
                  <span className="absolute left-12 top-2 my-auto">{name}</span>
                </label>
              </div>
              {selectedLength > 0 && (
                <div className="flex rounded-full px-2 border-2 border-white bg-[#2172FF] text-white text-[12px] py-[2px]">
                  <span className="me-3">{selectedLength} Selected</span>
                  <span onClick={() => handleRemove(index)} className="flex">
                    <img
                      src="/images/cross-white.svg"
                      className="h-[12px] w-[12px] my-auto"
                      alt="cross"
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default DashboardTable;
