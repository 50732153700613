export const adConfiguration = [
  {
    type: "html",
    name: "Name",
    value: ["image", "name"],
  },
  {
    type: "text",
    name: "#ID",
    value: "id",
  },
  {
    type: "text",
    name: "Business Name",
    value: "businessName",
  },
  {
    type: "status",
    name: "Status",
    value: "status",
  },
  {
    type: "actions",
    name: "Actions",
  },
];

export const campaignsConfiguration = [
  {
    type: "switch",
    id: "_id",
    name: "",
    value: "isActive",
  },
  {
    type: "html",
    name: "Product",
    value: [(row) => row.product.coverImage, "title"],
  },
  {
    type: "text",
    name: "Store Name",
    value: (row) => row.product.storeName,
  },
  {
    type: "text",
    name: "Pubslishers Name",
    value: "publisherName",
  },
  {
    type: "text",
    name: "Conversion",
    value: "conversion",
  },
  {
    type: "text",
    name: "Spent",
    value: "spent",
  },
  {
    type: "actions",
    name: "Actions",
  },
];

export const storeProductsConfiguration = [
  {
    type: "html",
    name: "Product",
    value: ["coverImage", "title"],
  },
  {
    type: "text",
    name: "Price",
    value: "price",
  },
];
export const dashboardConfiguration = [
  {
    type: "switch",
    name: "On/Off",
    value: "status",
  },
  {
    type: "text",
    name: "Campaign Name",
    value: "campaignName",
  },
  {
    type: "status",
    name: "Delievery",
    value: "delivery",
  },
  {
    type: "text",
    name: "Budget($)",
    value: "budget",
  },
  {
    type: "text",
    name: "Amount Spent($)",
    value: "amountSpent",
  },
  {
    type: "text",
    name: "Purchase",
    value: "purchase",
  },
  {
    type: "text",
    name: "Net Profit($)",
    value: "netProfit",
  },
];

export const publishersConfiguration = [
  {
    type: "text",
    name: "Name",
    value: "fullname",
  },
  {
    type: "text",
    name: "Email",
    value: "email",
  },
  {
    type: "text",
    name: "Linked Products",
    value: "linkedProductsNumber",
  },
  {
    type: "text",
    name: "Linked Ad Accounts",
    value: "linkedAccountsNumber",
  },
  {
    type: "status",
    name: "Status",
    value: "status",
  },
  {
    type: "actions",
    name: "Actions",
  },
];

export const productstConfiguration = [
  {
    type: "html",
    name: "Product",
    value: ["coverImage", "title"],
  },
  {
    type: "text",
    name: "Store",
    value: "storeName",
    link: "adminLink",
  },
  {
    type: "text",
    name: "Cost",
    value: "cost",
  },
  {
    type: "status",
    name: "Status",
    value: "status",
  },
  {
    type: "actions",
    name: "Actions",
  },
];

export const invitationsConfiguration = [
  {
    type: "text",
    name: "Email",
    value: "email",
  },
  {
    type: "text",
    name: "Invited in",
    value: "createdAt",
  },
  {
    type: "text",
    name: "Link",
    value: "link",
    link: "link",
  },
  {
    type: "status",
    name: "Status",
    value: "status",
  },
  {
    type: "actions",
    name: "Actions",
  },
];
