import { useState } from "react";
import Button from "../components/Button";
import { GoEye, GoEyeClosed } from "react-icons/go";
import api from "../utils/api";
import HeroImg from "../components/HeroImg";
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";

const ForgotPassword = () => {

   let { token } = useParams();
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [passwordVisibility, setPasswordVisibility] = useState(false);
   const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);
   const [error, setError] = useState(null);

   const navigate = useNavigate();

   const handleSubmit = async (e) => {
      e.preventDefault();

      if (!token) return;

      if (!password) {
         setError("Password is required");
         return;
      }

      if (password != confirmPassword) {
         setError("Password and confirm password should be equal");
         return;
      }

      setIsLoading(true);

      try {
         const res = await api.post("/forgot-password", {
            token: token,
            password: password
         });
         toast.success("Password reset successfully");
         navigate(`/login`)
      } catch (error) {
         toast.error("Something went wrong");
         setError(error?.response.data?.message);
      }

      setIsLoading(false);
   }

   return (
      <div className="h-screen flex">
         <HeroImg />
         <div className="w-[40%] h-full bg-[#fff] flex-col flex justify-center items-center  ">
            <div className="h-full flex justify-center items-center flex-col">
               <div className="w-[400px] bg-[#fff] rounded-[5px] flex gap-3  px-[40px]  ">
                  <div className=" flex flex-col gap-5 flex-1">
                     <div className="flex flex-col gap-1 items-center">
                        <h1 className="text-[20px] font-medium text-center text-[#0a1218] leading-[52px]">
                           Forgot password
                        </h1>
                     </div>
                     <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                        <div className="flex flex-col gap-4">
                           <div className="flex flex-col gap-4">
                              <label
                                 htmlFor="password"
                                 className="text-[16px] font-medium leading-6 text-[#0a1218]"
                              >
                                 Password
                              </label>
                              <div className="w-full flex justify-between items-center bg-white px-4 py-[14px] rounded-lg border border-[#CAD1DB]  focus-within:border-[#0D6EFD] ">
                                 <input
                                    value={password}
                                    id="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="text-[#080f18] text-[14px] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                                    type={passwordVisibility ? "text" : "password"}
                                 />
                                 <button
                                    type="button"
                                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                                 >
                                    {passwordVisibility ? (
                                       <GoEye className="text-2xl" />
                                    ) : (
                                       <GoEyeClosed className="text-2xl" />
                                    )}
                                 </button>
                              </div>
                           </div>
                           <div className="flex flex-col gap-4">
                              <label
                                 htmlFor="password"
                                 className="text-[16px] font-medium leading-6 text-[#0a1218]"
                              >
                                 Confirm Password
                              </label>
                              <div className="w-full flex justify-between items-center bg-white px-4 py-[14px] rounded-lg border border-[#CAD1DB]  focus-within:border-[#0D6EFD] ">
                                 <input
                                    value={confirmPassword}
                                    id="password"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="text-[#080f18] text-[14px] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                                    type={confirmPasswordVisibility ? "text" : "password"}
                                 />
                                 <button
                                    type="button"
                                    onClick={() => setConfirmPasswordVisibility(!confirmPasswordVisibility)}
                                 >
                                    {confirmPasswordVisibility ? (
                                       <GoEye className="text-2xl" />
                                    ) : (
                                       <GoEyeClosed className="text-2xl" />
                                    )}
                                 </button>
                              </div>
                           </div>
                        </div>
                        <div className="flex flex-col gap-3">
                           <Button
                              disabled={isLoading}
                              value={isLoading ? "Loading..." : "Submit"}
                              className="w-full font-medium text-[16px] leading-6 h-14 "
                           />
                        </div>
                        <p className="text-red-700">{error}</p>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
};

export default ForgotPassword;