import React, { useEffect, useState } from "react";
import { TableContextProvider } from "../context/TableContext";
import Page from "./Page";
import TableContainer from "../components/table/TableContainer";
import PublisherModal from "../components/modals/PublisherModal";
import { publishersConfiguration as tableConfiguration } from "../constant/tablesParam";
import api from "../utils/api";
import { useAppContext } from "../context/Context";
import { Navigate } from "react-router-dom";
import { useErrorContext } from "../context/ErrorContext";
const Publishers = () => {
  const {
    userData: { role },
  } = useAppContext();
  const [showPoblisherModal, setShowPoblisherModal] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);
    const {setStatus} = useErrorContext()

  const updateUsers = invitedUsers?.map((obj) => ({
    ...obj,
    status: "active",
  }));
  const actions = [
    {
      name: "Invite",
      action: () => setShowPoblisherModal(true),
      type: "primary",
      icon: {
        name: "UserPlus",
        color: "white",
      },
    },
  ];

  const emptyState = {
    actions,
    title: "Invite your first team member",
    subtitle:
      "Invite collaborators to your team to manage and publish cmapaigns ",
    image: "desk.png",
  };

  const fetchInvitedUsers = async () => {
    if (role === "admin") {
      try {
        setStatus(true)
        const { data } = await api.get("/get-users");
        setInvitedUsers(data.data);
        console.log(data.data, "publisher");
      } catch (error) {
        console.log(error, "error dataaa");
        if(error.response.status === 500) {
        setStatus(false)
      }
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    fetchInvitedUsers();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(userData.role);

  return role === "admin" ? (
    <Page title="Publishers" actions={actions}>
      <TableContextProvider
        {...{ tableConfiguration, data: updateUsers, emptyState }}
      >
        <PublisherModal
          setShowPoblisherModal={setShowPoblisherModal}
          fetchInvitedUsers={fetchInvitedUsers}
          showPoblisherModal={showPoblisherModal}
        />
        <TableContainer title={"Accounts"} />
      </TableContextProvider>
    </Page>
  ) : (
    <Navigate to={"/"} />
  );
};

export default Publishers;
