import React, {  useEffect, useState } from "react";
import { TableContextProvider } from "../context/TableContext";
import Page from "./Page";
import ProductPreview from "../components/previews/ProductPreview.jsx";
import { useAppContext } from "../context/Context";
import { productstConfiguration as tableConfiguration } from "../constant/tablesParam";
import { useErrorContext } from "../context/ErrorContext.jsx";
import Stores from "./Stores.jsx";
import SyncProductsModal from "../components/modals/SyncProductsModal";
import ProductsTable from "../components/table/ProductsTable";

const Products = () => {
  const { setShowModal, setProductState, productState, userData , showModal, fetchProductsEffect} = useAppContext();
  const [ showSyncModal, setShowSyncModal] = useState(false);
  const [showAssignPreview, setShowAssignPreview] = useState(false);

  useEffect(() => {
    fetchProductsEffect();
  }, []);

  const pageActions = [
    {
      name: "Sync products",
      action: () => setShowSyncModal(true),
      type: "secondary",
      icon: {
        name: "RefreshCcw",
        color: "#0D6EFD",
      },
    },
    {
      name: "Add store",
      action: () => setShowModal(true),
      type: "primary",
      icon: {
        name: "ShoppingCart",
        color: "white",
      },
    },
  ];

  const tableActions = [
    {
      name: "Assigne",
      action: () => setShowAssignPreview(true),
      type: "secondary",
      icon: { name: "GitPullRequestCreateArrow" },
    },
    {
      name: "Delete",
      action: () => console.log,
      type: "danger",
      icon: { name: "Trash" },
    },
  ];

  const emptyState = {
    actions: pageActions,
    title: "Import your first products",
    subtitle: "Connect to your store and syncronize products to start",
    image: "import-cloud.png",
  };

  const closePreview = () => {
    setShowAssignPreview(false)
  }

  return (
    <Page title="Products" actions={pageActions}>
      {/* <Stores check={showModal}/> */}
      <TableContextProvider
        {...{
          tableConfiguration,
          emptyState,
          selectable: userData.role === "admin" ? true : false,
          actions: tableActions,
        }}
      >
        {/* <TableContainer /> */}
        <ProductsTable {...{setProductState, productState}}/>
        {/* <AssigneProductsPreview showAssignPreview={showAssignPreview} closePreview={closePreview} fetchProducts={fetchProducts} /> */}
        {/* <ProductsTable /> */}
        <ProductPreview />
      </TableContextProvider>
      <SyncProductsModal
        showSyncModal={showSyncModal}
        setShowSyncModal={setShowSyncModal}
      />
    </Page>
  );
};

export default Products;
