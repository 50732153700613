import React, { useState } from 'react';
import { Plus, X } from 'lucide-react';
import { validShopifyDomain } from '../../../utils/helper';
import API from '../../../utils/api';
import Button from '../../../components/Button';
import { getProductsByStoreId } from '../../../utils/services';
import { useNavigate } from 'react-router';

const ShopifyInputDomain = ({ setDomains, domains, loading, setLoading, setShopifyProducts, handleContinue }) => {
    const [storeId, setStoreId] = useState(null);
    const [installState, setInstallState] = useState(null);
    const navigate = useNavigate();

    const isValid = (storeUrl) => validShopifyDomain(storeUrl);

    const goToInstall = (storeUrl) => {
        let link = `/admin/oauth/redirect_from_cli?client_id=4bc66ec32c62b5a063f2b7b8543ec9ce`;
        if (!storeUrl.startsWith('https://')) {
            link = `https://${storeUrl}${link}`;
        } else {
            link = `${storeUrl}${link}`;
        }
        window.open(link, '_blank');
    };

    const handleCheck = async () => {
        setLoading(true);
        const storeUrl = domains[0];
        try {
            const { data: storeData } = await API.get(`/store`, { params: { shop: storeUrl, platform: "Shopify" } });
            // to test on dev you can decomment the line below
            // setStoreId(storeData._id);
            // const { data } = await getProductsByStoreId("6729f3471b3389c053f6c23c");
            // setShopifyProducts(data);
            // handleContinue();
            if (storeData.accessToken) {
                setStoreId(storeData._id);
                const { data } = await getProductsByStoreId(storeData._id, "Shopify");
                setShopifyProducts(data);
                setInstallState('INSTALLED');
                handleContinue();
            } else {
                setInstallState('PENDING');
            }
        } catch (error) {
            console.log(error);
            setStoreId(null);
        } finally {
            setLoading(false);
        }
    };

    const handleAddStore = async () => {
        const storeUrl = domains[0];
        setLoading(true);
        if (!isValid(storeUrl)) return;
        try {
            const { data: newStore } = await API.post(`/store`, { shop: storeUrl });
            setStoreId(newStore._id);
            if (!newStore.accessToken) {
                goToInstall(storeUrl);
            } else {
                setInstallState('INSTALLED');
            }
        } catch (error) {
            console.log(error);
            setStoreId(null);
        } finally {
            setLoading(false);
        }
    };

    const handleDomainChange = (index, e) => {
        const value = e.target.value.replace(/^https?:\/\//, '');
        const newDomains = [...domains];
        newDomains[index] = `${value}`;
        setDomains(newDomains);
    };

    const addDomainField = () => setDomains([...domains, 'https://']);

    const removeDomainField = (index) => {
        const newDomains = domains.filter((_, i) => i !== index);
        setDomains(newDomains);
    };

    return (
        <div className="w-full max-w-4xl px-4">
            <h1 className="text-4xl font-semibold text-gray-900 mb-2 text-center">Enter your domain</h1>
            <h3 className="text-gray-600 mb-9 text-center">This helps us give you more relevant responses</h3>

            <form className="flex flex-col items-center">
                {domains.map((domain, index) => (
                    <div key={index} className="flex w-full max-w-md relative mb-4">
                        <span className="inline-flex items-center px-3 py-2 text-gray-500 bg-gray-100 border border-r-0 border-gray-300 rounded-l-md">https://</span>
                        <input
                            type="text"
                            value={domain.replace(/^https?:\/\//, '')} // Display without https://
                            onChange={(e) => handleDomainChange(index, e)}
                            placeholder="example.com"
                            className="flex-1 px-3 py-2 border border-gray-300 rounded-r-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                        />
                        {domains.length > 1 && (
                            <button
                                type="button"
                                onClick={() => removeDomainField(index)}
                                className="ml-2 text-gray-400 hover:text-red-500 transition-colors"
                                aria-label="Remove domain"
                            >
                                <X className="w-5 h-5" />
                            </button>
                        )}
                    </div>
                ))}
                
                <div className="w-full max-w-md mb-4">
                    <button type="button" onClick={addDomainField} className="flex items-center text-blue transition-colors">
                        <Plus className="w-4 h-4 mr-2" />
                        Add another domain
                    </button>
                </div>
            </form>

            <div className="flex flex-col gap-4">
                {!storeId && <Button value="Add store" onClick={handleAddStore} disabled={!isValid(domains[0]) || storeId} loading={loading} />}
                {storeId && <Button value="Check connection" onClick={handleCheck} loading={loading} />}
                <button className="py-2 px-9 rounded-lg w-full" onClick={() => navigate("/integration")}>Cancel</button>
            </div>
        </div>
    );
};

export default ShopifyInputDomain;
