import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='bg-[#F8F9FB] text-[#2b4d75] h-full py-4'>
    <div className={"w-[1000px] m-auto flex flex-col gap-2"}>
      <h1 className={"text-3xl font-bold my-10"}>Privacy Policy</h1>
      <p>
        Thank you for using the Meta Facebook app. Your privacy is important to
        us, and we are committed to protecting your personal information. This
        Privacy Policy explains how we collect, use, and safeguard your data
        when you use our app.
      </p>
      <h2 className={"text-lg font-bold "}>1. Information We Don't Collect</h2>
      <p>
        We want to be transparent about the data we do not collect:
        <ul>
          <li>Personal Data: We do not collect any personal information such as your name, address, email, or phone number.</li>
          <li>Sensitive Information: We do not collect any sensitive information such as racial or ethnic origin, political opinions, religious beliefs, health data, or sexual orientation.</li>
          <li>Location Data: We do not collect your precise or approximate location data.</li>
          <li>Browsing History: We do not track your browsing history or online activities outside of our app.</li>
        </ul>
      </p>
      <h2 className={"text-lg font-bold "}>2. Information We Don't Process, Store, or Sell</h2>
      <p>
        We want to assure you that we do not engage in the following activities with your data:
        <ul>
          <li>Processing Personal Data: We do not process any personal data provided by users.</li>
          <li>Storing Personal Data: We do not store any personal data provided by users.</li>
          <li>Selling Personal Data: We do not sell any personal data to third parties.</li>
        </ul>
      </p>
      <h2 className={"text-lg font-bold "}>3. Data Collection</h2>
      <p>
        While using our app, we may collect non-personal information for analytics purposes, such as:
        <ul>
          <li>Usage Data: Information about how you interact with the app, including pages visited and features used. This data is collected in aggregate and is not personally identifiable.</li>
          <li>Device Information: Information about your device such as device type, operating system, and device identifiers. This helps us optimize the app for different devices.</li>
        </ul>
      </p>
      <h2 className={"text-lg font-bold "}>4. Data Security</h2>
      <p>
        We take data security seriously and implement appropriate measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
      </p>
      <h2 className={"text-lg font-bold "}>5. Third-Party Services</h2>
      <p>
        Our app may contain links to third-party websites or services. Please note that we are not responsible for the privacy practices of these third parties. We encourage you to review the privacy policies of any third-party services before providing any personal information.
      </p>
      <h2 className={"text-lg font-bold "}>6. Children's Privacy</h2>
      <p>
        Our app is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If you believe that we may have collected personal information from a child under 13, please contact us immediately.
      </p>
      <h2 className={"text-lg font-bold "}>7. Changes to This Privacy Policy</h2>
      <p>
        We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on this page.
      </p>
      <h2 className={"text-lg font-bold "}>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@adclair.com">support@adclair.com</a>.
      </p>
      <p>
        By using the Meta Facebook app, you agree to the terms and conditions outlined in this Privacy Policy. Thank you for trusting us with your privacy.
      </p>
      <p>Last Updated: 15/01/2024</p>
    </div></div>
  );
};

export default PrivacyPolicy;
