import React from "react";
import Icon from "./Icon";
import { Label } from "flowbite-react/components/Label";

const Textarea = ({
  onChange,
  bindContext,
  type = "text",
  value,
  placeholder = "",
  label,
  icon,
  error = "",
  disabled,
  rows= 4,
  onFocus
}) => {

  
  const handleChange = (value) => {
    if(onChange){
      onChange(value)
    }
    if(bindContext){
      const [{setItem}, path] = bindContext
      setItem(path, value.target.value)
    }
  }


  const handleFocus = () => {
    if(onFocus){
      onFocus()
    }
  }

  return (
    <div className="flex gap-1 flex-col">
       {label && (
         <div className="mb-2 block">
         <Label htmlFor="" value={label} />
       </div>
      )}
      <div>
        <div
          className={`p-2 justify-center border border-[#CAD1DB] rounded-lg flex items-center gap-2 transition-all 
            ${
              disabled
                ? "bg-[#F8F9FB]"
                : "bg-[#f9fafb] focus-within:border-[#0D6EFD] "
            }
            ${error ? "border-[#F5432C]" : ""}
            `}
        >
          {icon && <Icon {...icon} size="18" color="#63657E" />}
          <textarea
            rows={rows}
            type={type}
            className={`w-full text-[#63657E] text-[14px] h-full outline-none border-none ${
              disabled ? "bg-[#F8F9FB]" : "bg-[#f9fafb]"
            }`}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </div>
        {error && (
          <div className="mx-2 font-small text-[12px] text-[#F5432C]">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default Textarea;
