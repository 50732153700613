import React, { useEffect, useState } from "react";

import { useAppContext } from "../context/Context";
import Page from "./Page";
import Api from "../utils/api";
import CanvasJSReact from "@canvasjs/react-charts";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SuperAdmin = () => {
  const { userData } = useAppContext();
  const { fullname } = userData;
  const [data, setData] = useState([]);
  const [options, setOptions] = useState();
  useEffect(() => {
    const fetchStats = async () => {
      const { data } = await Api.get("/superadmin/stats");
      setData(data);
    };
    fetchStats();
  }, []);

  useEffect(() => {
    if (data?.charts?.usersChart) {
      let acc = 0;
      setOptions({
        animationEnabled: true,
        title: {
          text: "Users",
        },
        axisX: {
          valueFormatString: "DD",
        },
        axisY: {
          title: "Users",
          sufix: "Users",
        },
        data: [
          {
            xValueFormatString: "DD",
            type: "spline",
            dataPoints: data.charts.usersChart.map((stat) => {
              acc += stat.count;
              return {
                x: new Date(stat._id),
                y: acc,
              };
            }),
          },
        ],
      });
    }
  }, [data?.charts]);
  return (
    <Page title={"Admin Panel"} fullWidth={false}>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {data?.counts &&
          Object.keys(data.counts).map((key, index) => (
            <div key={key} className=" bg-white rounded shadow border p-4">
              <h1 className="font-bold text-xl capitalize">{key}</h1>
              <p className="text-black-100">{data.counts[key]}</p>
            </div>
          ))}
      </div>
      {options && (
        <CanvasJSChart
          options={options}
          /* onRef={ref => this.chart = ref} */
        />
      )}
    </Page>
  );
};

export default SuperAdmin;
