import React, { useState } from 'react';
import { ReactComponent as Empty } from '../../svgs/empty.svg';
import CreateIntegration from './CreateIntegration';
import { useNavigate } from 'react-router';

const EmptyIntegrations = () => {
  const navigate = useNavigate();

  const handleAddIntegration = () => {
    navigate("/integration/create");
  };

  return (
    <div className="min-h-screen flex items-center justify-center"> 
        <div className="text-center max-w-3xl mx-auto p-6">
          <div className="mb-8">
            <Empty className="mx-auto" />
          </div>
          <h1 className="text-2xl font-bold mb-4">
            Next Step: Set up a data stream to start collecting data
          </h1>
          <h3 className="text-gray-600 mb-8">
            After creating a data stream, you'll get tagging information
          </h3>
          <button
            onClick={handleAddIntegration}
            className="bg-[#1972F5] hover:bg-blue-600 text-white font-medium py-2 px-6 rounded-lg transition duration-200"
          >
            Add Integration
          </button>
        </div>
    </div>
  );
};

export default EmptyIntegrations;
