import React from 'react'
import Modal from '../Modal'
import formatNewsDate from '../../utils/formatNewsDate';

const NewsModal = ({ showModal, setShowModal, news }) => {
    return (
        <Modal {...{ setShowModal, showModal, title: "✨ What's new" }}>
            <div className='w-[500px] max-h-[600px] overflow-auto divide-y'>
                {
                    news?.map((item) => (
                        <div key={item._id} className="news-item mb-4">
                            <p className="text-sm text-gray-500 my-4">
                                {formatNewsDate(item.activationDate)}
                            </p>
                            <h2 className="text-xl font-semibold">{item.title}</h2>
                            {item.cover && <img src={item.cover} alt="cover" className="my-2 rounded-md" />}
                            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                        </div>
                    ))
                }
            </div>
        </Modal>
    );
};

export default NewsModal;
