import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useAppContext } from "../context/Context";
import { addRefresh, addToken, addUser } from "../utils/localStorage";
import Button from "../components/Button";
import { HiMail } from "react-icons/hi";
import api from "../utils/api";
import { useErrorContext } from "../context/ErrorContext";
import HeroImg from "../components/HeroImg";

import FlowInput from "../components/flowbite/Input";

const Login = () => {
  const navigate = useNavigate();
  const { setUserData } = useAppContext();
  const { setStatus } = useErrorContext();
  const [searchParams] = useSearchParams();
  
  const [eyeOpen, setEyeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    // termsAndConditions: false,
    isSocialLogIn: false,
  });


  useEffect(() => {
    const email = searchParams.get("email")
    if (email) {
      setFormData({
        ...formData,
        email: email,
      });
    }
  }, [searchParams]);

  // const isFormDataFilled = () => {
  //   for (const key in formData) {
  //     if (formData.hasOwnProperty(key)) {
  //       if (formData[key] === "") {
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    // if(!isFormDataFilled()) return
    e.preventDefault();
    try {
      setStatus(true);
      setIsLoading(true);
      setError("");
      const res = await api.post("/sign-in", {
        email: formData.email,
        password: formData.password,
        isSocialLogIn: formData.isSocialLogIn,
        // termsAndConditions: formData.termsAndConditions,
      });
      setUserData(res.data.data);
      addUser(res.data.data);
      addToken(res.data.data.authToken);
      addRefresh(res.data.data.refreshToken);
      console.log(res);
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.log(error.message);
      setError(error?.response.data?.message);
      setIsLoading(false);
      if (error.response.status === 500) {
        setStatus(false);
      }
    }
  };

  return (
    <div className="h-screen flex">
      <div className="w-full lg:w-[40%] h-full bg-[#fff] flex-col flex justify-center items-center  ">
        <div className="h-full flex justify-center items-center flex-col">
        <img src="/images/logo-black-long.png" alt="logo" className="h-20 lg:hidden  " />
          <div className="w-[400px] bg-[#fff] rounded-[5px] flex gap-3  px-[40px]  ">
            <div className=" flex flex-col gap-5 flex-1">
              <div className="flex flex-col gap-1 items-center">
                {/* <div className="h-20  m-4 ">
                <img src="/images/logo2.png" alt="logo"/>
              </div> */}

                <h1 className="text-[20px] font-medium text-center text-[#0a1218] leading-[52px]">
                  Welcome Back!
                </h1>
                <p className="text-center text-[15px] leading-7 font-normal text-[#0a1218] ">
                  Sign in to your account below
                </p>
              </div>
              <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <FlowInput
                    label="Email"
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={isLoading}
                    icon={HiMail}
                  />
                  <FlowInput
                      label="Password"
                      type={eyeOpen ? "text" : "password"}
                      id="password"
                      value={formData.password}
                      onChange={handleChange}
                      disabled={isLoading}
                      addon={
                        <button
                          type="button"
                          onClick={() => setEyeOpen(!eyeOpen)}
                        >
                        {eyeOpen ? (
                          <GoEye className="text-2xl" />
                        ) : (
                          <GoEyeClosed className="text-2xl" />
                        )}
                      </button>
                      }
                    />

                  <Link className="text-[#0D6EFD] text-right text-[14px] mb-6" to={"/forgot-password"}>
                    Forgot password
                  </Link>
                </div>
                {/* <div className="flex gap-2 ">
                  <input
                    type="checkbox"
                    className="w-5 h-5 flex items-center p-[2px]"
                    id="termsAndConditions"
                    value={formData.termsAndConditions}
                    onChange={handleChange}
                  />
                  <label htmlFor="termsAndConditions" className="text-[14px]">
                    I agree to the terms and conditions and the privacy
                    policy
                  </label>
                </div> */}
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  value={"Login"}
                  className="w-full font-medium text-[16px] leading-6 h-14 "
                />
                <p className="text-red">{error}</p>
                <p className="w-full text-center text-[16px] font-normal leading-4 text-[#0a1218]">
                  {/* OR */}
                </p>
              </form>
            </div>
          </div>
          <div className="flex flex-col ">
            {/* <OAuth /> */}
            {/* <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-[#2b4d75]">
              Don't Have an account?{" "}
              <Link className="text-[#0D6EFD]" to={"/signup"}>
                Sign up
              </Link>
            </p> */}
          </div>
        </div>
        <div className="bg-[#f1f4f8] w-full p-4">
          <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-[#6d777f]">
            2024 AdClair. All Rights Reserved |{" "}
            <Link
              className="text-[#0D6EFD]"
              onClick={() => window.open("/privacy-policy", "_blank")}
            >
              Privacy policy
            </Link>
            <span> - </span>
            <Link
              className="text-[#0D6EFD]"
              onClick={() => window.open("/terms-of-use", "_blank")}
            >
              Terms of Use
            </Link>
          </p>
        </div>
      </div>
      <HeroImg />
    </div>
  );
};

export default Login;
