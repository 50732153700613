import React, { useEffect } from 'react';

const ChooseYourSystem = ({ onSelect, selectedSystem, setStepError, handleContinue, handleFinish }) => {
    useEffect(() => {
        setStepError(true); 
    }, [setStepError]); 

    const companies = [
        { id: 1, name: "Shopify", logo: "/images/logos/shopify.png", available: true },
        { id: 2, name: "Flash Delivery", logo: "/images/logos/flashdelivery.png", available: true },
        { id: 3, name: "Youcan", logo: "/images/logos/youcan.png", available: false },
        { id: 4, name: "LightFunnels", logo: "/images/logos/lightfunnels.png", available: true },
        { id: 5, name: "WooCommerce", logo: "/images/logos/woocommerce.png", available: false },
        { id: 6, name: "ClickFunnels", logo: "/images/logos/clickfunnels.png", available: true },
        { id: 7, name: "Shopyan", logo: "/images/logos/shopyan.png", available: true },
    ];

    const handleSelect = (systemName) => {
        onSelect(systemName);
        setStepError(false); 
    };

    return (
        <div className="w-full max-w-4xl px-4">
            <h1 className="text-4xl font-semibold text-gray-900 mb-2 text-center">What is your shop system?</h1>
            <h3 className="text-gray-600 mb-9 text-center">Choose the system which you are either currently using or you plan to use</h3>

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-9">
                {companies.map((company) => (
                    <label
                        key={company.name}
                        className={`relative hover:shadow flex flex-col bg-white items-center p-4 border rounded-lg cursor-pointer hover:border-blue-500 transition-all ${
                            selectedSystem === company.name ? 'border-blue-500 bg-blue-50 shadow bg-white' : 'border-gray-200'
                        }`}
                    >
                        <input
                            type="radio"
                            name="system"
                            value={company.name}
                            checked={selectedSystem === company.name}
                            onChange={() => handleSelect(company.name)} // Call handleSelect on change
                            className="absolute top-2 right-2"
                        />
                        <div className="w-24 h-24 flex items-center justify-center p-2">
                            <img
                                src={company.logo}
                                alt={`${company.name} logo`}
                                className="w-full h-full object-contain"
                            />
                        </div>
                        <span className="font-medium text-gray-900 mt-2 text-center">{company.name}</span>
                    </label>
                ))}
            </div>
            <div className="flex flex-col justify-center gap-4 mb-2 w-full px-4">
                <div className="flex flex-col gap-4">
                    <button onClick={handleContinue} className="bg-[#1972F5] py-2 px-9 rounded-lg text-white w-80 mx-auto">
                        Continue
                    </button>
                    <button onClick={handleFinish} className="py-2 px-9 rounded-lg w-full">
                        Cancel
                    </button>
                </div>
            {/* <div className="flex flex-col gap-4">
                <button className="bg-[#1972F5] py-2 px-9 rounded-lg text-white w-80 mx-auto">
                    Done
                </button>
                <button className="py-2 px-9 rounded-lg w-full">
                    Back
                </button> */}
            {/* </div> */}
        </div>

        </div>
    );
};

export default ChooseYourSystem;
