// import Workspace from "../../../backend/model/workspace.model";
import Api from "./api";
import Axios from "axios";

export const defaultFetchState = { loading: false, data: [], error: null };

export const fetchAdAccountsService = async () => {
  try {
    const { data } = await Api.get("/adaccounts");
    return {
      loading: false,
      data: data.adAccounts.map(
        ({
          id,
          accountUserId,
          currency,
          image,
          platform,
          status,
          businessName,
          name,
          _id,
        }) => ({
          id,
          accountUserId,
          currency,
          image,
          platform,
          status,
          name,
          businessName: businessName || "-",
          _id,
        })
      ),
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: [],
      error: error,
    };
  }
};

export const fetchCampaignsService = async ({
  adAccountId,
  selectedLevelLabel,
  since,
  until,
  campaignsFilterIds,
  adSetsFilterIds,
  adsFilterIds,
  searchTerm
}) => {
  try {
    const { data } = await Api.get(
      `/campaigns?adAccountId=${adAccountId}&level=${selectedLevelLabel}&campaignsFilterIds=${campaignsFilterIds.join(
        ","
      )}&adSetsFilterIds=${adSetsFilterIds.join(
        ","
      )}&adsFilterIds=${adsFilterIds.join(",")}&since=${since}&until=${until}&searchTerm=${searchTerm}`
    );
    return [
      {
        loading: false,
        data: data.insight,
        error: null,
      },
      {
        loading: false,
        data: data.orders,
        error: null,
      },
    ];
  } catch (error) {
    return [
      {
        loading: false,
        data: [],
        error: error,
      },
      {
        loading: false,
        data: [],
        error: error,
      },
    ];
  }
};

export const fetchStoresService = async () => {
  try {
    const { data } = await Api.get("/stores");
    return {
      loading: false,
      data: data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: [],
      error: error,
    };
  }
};

export const fetchProductsService = async (profile) => {
  try {
    const { data } = await Api.get(profile === "admin" ? "/getAllProducts" : "/getProductsByPublisherId");
    return {
      loading: false,
      data: data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: [],
      error: error,
    };
  }
};

export const switchCampaignStatus = async (
  adAccountId,
  metaCampaignId,
  status
) => {
  try {
    const { data } = await Api.patch(
      `/switchCampaign/${adAccountId}/${metaCampaignId}?status=${status}`
    );
    return {
      loading: false,
      data: data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: {},
      error: error,
    };
  }
};


export const updateProductService = async (payload) => {
  try {
    // Payload
    // {
    //   productIds: productId, 
    //   idsPrice: payload, // {id,cost}
    //   publishers: publisher, // {fullname, publisherId}
    // }
    const { data } = await Api.patch(`/updateProducts`, payload);
    return {
      loading: false,
      data: data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: {},
      error: error,
    };
  }
}


export const deleteProductService = async (productId) => {
  try {
    const {data} = await Api.delete(`/deleteProduct/${productId}`);
    return {
      loading: false,
      data: data?.success,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: false,
      error: error,
    };
  }
}

export const deleteAdAccountService = async (adAccountId) => {
  try {
    const {data} = await Api.delete(`/adaccount/${adAccountId}`);
    return {
      loading: false,
      data: data?.success,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: false,
      error: error,
    };
  }
}


export const getUserOnboarding = async () => {
  try {
    const {data} = await Api.get(`/user/onboarding`);
    return {
      loading: false,
      data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: {},
      error: error,
    };
  }
}

export const searchCountriesService = async (searchTerm) => {
  try {
    const { data }  = await Api.get(`/searchCountries?searchTerm=${searchTerm}`);
    return {
      loading: false,
      data: data.data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: [],
      error: error,
    };
  }
};

export const getProductsByStoreId = async (storeId, platform ="Shopify") => {
  let route =""
  switch(platform){
    case "Shopify":
      route = "/getProductsByStoreId";
      break;
    case "Youcan":
      route = "/youcan/products";
      break;
    default:
      route = "/getProductsByStoreId";
  }
  try {
    const { data } = await Api.post(route, {
      storeId: storeId,
      platform,
      all: false,
    });
    
    return {
      loading: false,
      data: data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: [],
      error: error,
    };
  }
};


export const hadleS3Upload = async (files) => {
  return await Promise.all(
    files.map(async (file) => {
      const { data } = await Api.get("/media/sign", {
        params: {
          name: file.name,
          contentType: file.type,
        },
      });
      const { fileName, uploadURL } = data;

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: uploadURL,
        headers: {
          "Content-Type": file.type,
        },
        data: await file.arrayBuffer(),
      };
      await Axios(config);
      return [fileName, file.name] 
    })
  );
};

export const generateCreatives = async (productId, storeId) => {
  try {
    const { data } = await Api.get(`/ai/creatives?productId=${productId}&storeId=${storeId}`);
    return {
      loading: false,
      data: data.message,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: "",
      error: error,
    };
  }
}

export const updateLocalStorage = async (payload) => {
  try {
    const { data } = await Api.post(`/user/localstorage`,payload);
    return {
      loading: false,
      data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: {},
      error: error,
    };
  }
}



const INTEGRATIONS_API_URL = '/integrations';
const TOKENWITHSCOOP_API_URL = '/generate-token-scope';


export const createIntegration = async (integrationData) => {
    try {
        const { data } = await Api.post(INTEGRATIONS_API_URL, integrationData);
        return {
            loading: false,
            data: data,
            error: null,
        };
    } catch (error) {
        return {
            loading: false,
            data: {},
            error: error,
        };
    }
};


export const getIntegration = async (integrationData) => {
  try {
      const { data } = await Api.get(INTEGRATIONS_API_URL, integrationData);
      return {
          loading: false,
          data: data,
          error: null,
      };
  } catch (error) {
      return {
          loading: false,
          data: {},
          error: error,
      };
  }
};

export const deleteIntegration = async (integrationId) => {
  try {
      const { data } = await Api.delete(`${INTEGRATIONS_API_URL}/${integrationId}`);
      return {
          loading: false,
          data: data,
          error: null,
      };
  } catch (error) {
      return {
          loading: false,
          data: {},
          error: error,
      };
  }
};


export const getTokeWithScoope = async () => {
  try {
    const { data } = await Api.post(TOKENWITHSCOOP_API_URL, {});
    
    return {
      loading: false,
      data: data,
      error: null,
    };
  } catch (error) {
    return {
      loading: false,
      data: {},
      error: error,
    };
  }
};


export const toggleIntegrationStatus = async (integrationId) => {
  try {
    const { data } = await Api.patch(`${INTEGRATIONS_API_URL}/${integrationId}/toggle-status`);
    return {
        loading: false,
        data: data,
        error: null,
    };
  } catch (error) {
      return {
          loading: false,
          data: {},
          error: error,
      };
  }
}


export const getIntegrationById = async (integrationId) => {
  try {
      const { data } = await Api.get(`${INTEGRATIONS_API_URL}/${integrationId}`);
      return {
          loading: false,
          data: data,
          error: null,
      };
  } catch (error) {
      return {
          loading: false,
          data: {},
          error: error,
      };
  }
}
