import React, { useEffect, useState } from "react";
import Select from "react-select";
import Preview from "./Preview";
import { useTableContext } from "../../context/TableContext.jsx";
import { useAppContext } from "../../context/Context";
import Input from "../Input";
import Card from "../Card";
import Button from "../Button";
import api from "../../utils/api.js";
import { useErrorContext } from "../../context/ErrorContext.jsx";

const AdAccountPreview = ({ fetchAdAccounts }) => {
  const { selectedItem, setSelectedItem } = useTableContext();
  const {
    userData: { role },
  } = useAppContext();
  // const [adData, setAdData] = useState(selectedItem);
  const [selectedPublishers, setSelectedPublishers] = useState([]);
  const [allPublishers, setAllPublishers] = useState();
  const [isLoading, setIsLoading] = useState(false);
    const { setStatus} = useErrorContext()


  const {
    id: adAccountId,
    businessName,
    currency,
  } = selectedItem || {}; // TODO: get single ad account details: Done

  const fetchData = async () => {
    if (role === "admin") {
      try {
        setStatus(true)
        const { data: publishers } = await api.get("/get-users");
        setAllPublishers(
          publishers?.data?.map(({ fullname, _id }) => ({
            label: fullname,
            value: _id,
          }))
        );
      } catch (error) {
        console.log(error, "error dataaa");
        if(error.response.status === 500) {
        setStatus(false)
      }
      }
    }

    // TODO: get single ad account details
    // try {
    //   const { data: singleProduct } = await api.get(`/getSingleProduct/${selectedItem._id}`);
    //   setProductData(singleProduct.data)
    //   // setAllPublishers(publishers.data.map(({ fullname, _id }) => ({ label: fullname, value: _id })));
    // } catch (error) {
    //   console.log(error, "error dataaa");
    // }

    return;
  };

  useEffect(() => {
    if (selectedItem?._id) {
      fetchData();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const publisherArr = function (arr) {
    return arr?.map((obj) => ({
      fullName: obj?.label,
      publisherId: obj?.value,
    }));
  };

  const adAccount = selectedItem?._id; // for 1 ad accounts

  const submitHandler = async () => {
    if (selectedPublishers) {
      setIsLoading(true);
      try {
        setStatus(true)
        const publishers = publisherArr(selectedPublishers);
        console.log("🚀 ~ submitHandler ~ publishers:", publishers)
        const response = await api.post("/addAssigneToAdAccountsSingle", {
          adAccountId : adAccount,
          publishers,
        });
        setSelectedPublishers(
          response?.data?.data?.assigne?.map(({ fullName, publisherId }) => ({
            label: fullName,
            value: publisherId,
          }))
        );
        fetchAdAccounts();
        setIsLoading(false);
        setSelectedItem(null)
      } catch (err) {
        console.log("Error", err?.message);
        setIsLoading(false);
        if(err.response.status === 500) {
        setStatus(false)
      }
      }
    }
  };

  // for getting all the publishers from the selectedItems and set in select-options
  useEffect(() => {
    const tempArr = selectedItem?.assigne?.map((obj) => {
      return {
        label: obj?.fullName,
        value: obj?.publisherId,
      };
    });
    setSelectedPublishers(tempArr);
  }, [selectedItem]);

  return (
    <Preview
      showPreview={selectedItem}
      handleToggle={() => setSelectedItem(null)}
      title={`AdAccount - ${adAccountId}`}
    >
      <div className="flex flex-col max-h-[100%] overflow-auto gap-4 justify-between h-full">
        <div className="flex gap-4 flex-col">
          <Card title="Details" icon="ReceiptText">
            <Input label="ID" value={adAccountId} disabled={true} />
            <Input label="Business name" value={businessName} disabled={true} />
            <Input label="Currency" value={currency} disabled={true} />
          </Card>
          {role === "admin" ? (
            // UserRoundCog
            <Card title={"Publishers"} icon="Lock">
              {/* not showing updated publishers */}
              {/*TODO: thinking of new Logic for that */}
              <Select
                isMulti
                className="basic-single"
                classNamePrefix="select"
                isDisabled={true}
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                name="publisher"
                placeholder="Select publishers of this product.."
                value={selectedPublishers}
                onChange={setSelectedPublishers}
                options={allPublishers || []}
              />
            </Card>
          ) : undefined}
        </div>
        {role === "admin" ? (
          <div className="flex items-center mb-10 justify-end">
            <Button
              value="Cancel"
              type="secondary"
              onClick={() => setSelectedItem(null)}
            />
            <Button
              disabled={isLoading}
              value="Save"
              icon={{ name: "Save" }}
              onClick={() => submitHandler()}
            />
          </div>
        ) : undefined}
      </div>
    </Preview>
  );
};

export default AdAccountPreview;
