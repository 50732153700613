import React from 'react';
import { HelpCircle } from 'lucide-react';

const IntegrationSteper = ({ 
  steps, 
  activeStep, 
  className = '' 
}) => {
  return (
    <div className={`bg-white rounded-lg shadow-sm p-6 flex flex-col h-full ${className}`}>
      <div className="flex-1 mt-16">
        {steps.map((step, index) => (
          <div key={step.label} className="relative">
            {index !== steps.length - 1 && (
              <div 
                className={`absolute left-[15px] top-[30px] w-[2px] h-[calc(100%-10px)] 
                  ${index < activeStep ? 'bg-green' : 
                    index === activeStep ? 'bg-gray-200' : 'bg-gray-200'}`}
              />
            )}
            
            <div className="flex items-start mb-8">
              <div 
                className={`relative flex items-center justify-center w-8 h-8 rounded-full border-2 
                  ${index < activeStep ? 'border-green bg-green' : 
                    index === activeStep ? 'border-gray bg-white' : 
                    'border-gray-200 bg-white'}
                  transition-colors duration-200`}
              >
                
                {index < activeStep ? (
                  <svg 
                    className="w-4 h-4 text-white" 
                    viewBox="0 0 20 20" 
                    fill="currentColor"
                  >
                    <path 
                      fillRule="evenodd" 
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" 
                      clipRule="evenodd" 
                    />
                  </svg>
                ) : (
                  <span 
                    className={`text-sm font-medium
                      ${index === activeStep ? 'text-blue-500' : 'text-gray-400'}`}
                  >
                    {index + 1}
                  </span>
                )}
              </div>

              <div className="ml-4 flex-1">
                <h3 
                  className={`text-sm font-medium mb-1
                    ${index === activeStep ? 'text-blue-500' : 
                      index < activeStep ? 'text-green-500' : 'text-gray-500'}`}
                >
                  {step.label}
                </h3>
                <p className="text-sm text-gray-600 mb-1">
                  {step.description}
                </p>
                <p className="text-xs text-gray-500">
                  {step.detail}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-auto border-t pt-6">
        <div className="text-center mb-4">
          <h4 className="text-base font-medium text-gray-800 mb-1">
            Having trouble?
          </h4>
          <p className="text-sm text-gray-600">
            Our support team is here to help you with the integration process
          </p>
        </div>
        <button 
          onClick={() => window.open('mailto:support@example.com')}
          className="w-full flex items-center justify-center gap-2 text-gray-600 hover:text-gray-800 bg-gray-50 hover:bg-gray-100 py-2 px-4 rounded-lg transition duration-200"
        >
          <HelpCircle className="w-4 h-4" />
          <span className="text-sm font-medium">Contact Support</span>
        </button>
      </div>
    </div>
  );
};

export default IntegrationSteper
