import React, { useState } from "react";
import { nanoid } from "nanoid";
import { DropZone } from "./drop-zone";
import { FilesList } from "./files-list";
import { hadleS3Upload } from "../../utils/services";

const FilePicker = ({ accept, value, bindContext }) => {
  const [loading, setLoading] = useState(false);

  const handleOnChange = async (files) => {
    setLoading(true);
    try {
      const fileUrls = await hadleS3Upload(Array.from(files));
      const adsets = fileUrls.map(([fileName, originalFileName]) => {
        return {
          fileName: originalFileName,
          fileId: nanoid(),
          mediaURL:
            "https://monsterocu-dev-publicfiles.s3.eu-west-2.amazonaws.com/" +
            fileName,
          title: "",
          adCopy: "",
        };
      });

      if (bindContext) {
        const [{ setItem, state }, path] = bindContext;
        setItem(path, [...state.settings.adsets, ...adsets]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearFile = (id) => {
    if (bindContext) {
      const [{ setItem, state }, path] = bindContext;
      const _adsets = [...state.settings.adsets].filter(
        (adset) => adset.fileId !== id
      );
      setItem(path, _adsets);
    }
  };

  return (
    <div className="rounded p-2 gap-4 flex flex-col">
      <div>
        <DropZone onChange={handleOnChange} accept={accept} loading={loading} />
      </div>

      {value.length ? (
        <div>
          <FilesList
            files={value}
            onClear={handleClearFile}
            loading={loading}
          />
        </div>
      ) : null}
    </div>
  );
};

export { FilePicker };

//doc: https://codesandbox.io/p/sandbox/filepicker-new-forked-3mipw9?ref=retool-blog
