import React, { useContext, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Pencil, Trash2, ExternalLink } from 'lucide-react';
import Switcher from '../../components/table/Switcher';
import { statusBodyTemplate } from '../../components/table/Cells';
import { IntegrationContext, IntegrationContextProvider } from '../../context/IntegrationContext';
import { deleteIntegration, getIntegration, toggleIntegrationStatus, getIntegrationById } from '../../utils/services'
import { getPlatformLogoPath } from '../../utils/helper';



const IntegrationsDashboard = () => {
    const { integrations, setIntegrations, loading } = useContext(IntegrationContext);
    const handleToggle = async (id) => {
        try {
            const response = await toggleIntegrationStatus(id);
            const updatedIntegration = response.data.integration;
            setIntegrations(integrations.map(integration =>
                integration._id === id ? { ...integration, isActive: updatedIntegration.isActive } : integration
            ));
        } catch (error) {
            console.error("Failed to toggle integration status:", error);
        }
    };
    const confirmDelete = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this integration?',
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDelete(id),
        });
    };

    const handleDelete = async (id) => {
        try {
            const delres = await deleteIntegration(id);
            const response = await getIntegration();
            setIntegrations(response.data.integrations);
        } catch (error) {
            console.error("Failed to delete integration:", error);
        }
    };

    const storeTemplate = (rowData) => {
        return (
            <div className="flex items-center gap-2">
                <img src={getPlatformLogoPath(rowData.platform)} alt={rowData.platform} className="w-8 h-8 rounded-full" />
                <div>
                    <div className="text-sm font-medium">{rowData.integrationName}</div>
                </div>
            </div>
        );
    };
    

    const customDomainTemplate = (rowData) => {
        return (
            <div className="flex items-center gap-2">
                <a href={rowData.domainNames[1]} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    {rowData.domainNames[1]}
                </a>
                <ExternalLink className="text-gray-500 cursor-pointer ml-1" size={16} />
            </div>
        );
    };

    const statusTemplate = (rowData) => {
        const isActive = !(rowData.status === 'Pending' || rowData.status === 'Error');
        if(rowData.status === 'Pending') {
            return statusBodyTemplate('Pending');
        }
        else if(rowData.status === 'Error') {
            return statusBodyTemplate('Error');
        }
        else if(isActive) {
            return statusBodyTemplate('Active');
        }

    };

    const actionsTemplate = (rowData) => {
        return (
            <div className="flex items-center justify-center gap-3">
                <button
                    onClick={() => console.log('Edit:', rowData._id)}
                    className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
                    title="Edit"
                >
                    <Pencil size={18} />
                </button>
                <button
                    onClick={() => confirmDelete(rowData._id)}
                    className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
                    title="Delete"
                >
                    <Trash2 size={18} />
                </button>
            </div>
        );
    };
    return (
        <div className="card">
            <ConfirmDialog />
            <div className="my-3 rounded mx-4 shadow">
                <DataTable
                    value={integrations}
                    loading={loading}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    stripedRows
                    resizableColumns
                    sortMode="multiple"
                    showGridlines
                >
                    <Column
                        header="Active"
                        body={(rowData) => (
                            <Switcher
                                state={rowData.isActive}
                                onChange={(newValue) => handleToggle(rowData._id, newValue)}
                            />
                        )}
                        style={{ width: '10%' }}
                    />
                    <Column field="_id" header="ID" sortable style={{ width: '10%' }} />
                    <Column header="Store Name" body={storeTemplate} style={{ width: '30%' }} />
                    <Column header="Custom Domain" body={customDomainTemplate} style={{ width: '30%' }} />
                    <Column header="Status" body={statusTemplate} style={{ width: '10%' }} />
                    <Column header="Actions" body={actionsTemplate} style={{ width: '10%' }} />
                </DataTable>
            </div>
        </div>
    );
};

export default IntegrationsDashboard;
