import { useEffect } from "react";
import api from "../utils/api";
import { useAppContext } from "../context/Context";

const useSubscriptionData = () => {
  const { setSubscriptionData, setSubscriptionDetailsLoading } =
    useAppContext();

  const fetchUser = async () => {
    try {
      setSubscriptionDetailsLoading(true);
      const res = await api.get("/getSubscriptionDetails");
      setSubscriptionData(res.data);
      setSubscriptionDetailsLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        setSubscriptionDetailsLoading(false);
        console.error("Server Error:", error);
      } else {
        console.error("Error fetching subscription details:", error);
      }
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);
};

export default useSubscriptionData;
