import { useState, useEffect } from 'react';
import { fetchAdAccountsService } from '../utils/services';

const useAdAccounts = () => {
    const [adAccountsState, setAdAccountsState] = useState([]);

        const fetchAdAccounts = async () => {
            try {
                const response = await fetchAdAccountsService()
                setAdAccountsState(response);
            } catch (error) {
                console.error('Error fetching ad accounts:', error);
            }
        };
    useEffect(() => {

        fetchAdAccounts();
    }, []);

    return [adAccountsState,setAdAccountsState,  fetchAdAccounts];
};

export default useAdAccounts;