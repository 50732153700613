import React, { useState } from 'react';
import ShopifyInputDomain from './ShopifyInputDomain';
import ShopifyProducts from './ShopifyProducts';
import ThankYouIntegration from '../Components/ThankYouIntegration';


const ShopifyIntegration = ({ handleContinue, handleBack, activeStep }) => {
  const [domains, setDomains] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [shopifyProducts, setShopifyProducts] = useState([]);

  return (
    <>
      {activeStep === 1 && (
        <ShopifyInputDomain
          setDomains={setDomains}
          domains={domains}
          loading={loading}
          setLoading={setLoading}
          setShopifyProducts={setShopifyProducts}
          handleContinue={handleContinue}
        />
      )}
      {activeStep === 2 && 
        (<ShopifyProducts 
          shopifyProducts={shopifyProducts}
          domains={domains}
          handleContinue={handleContinue}
          loading={loading}
          setLoading={setLoading}
          />
        )}
      {activeStep === 3 && <ThankYouIntegration />}
    </>
  );
};

export default ShopifyIntegration;
