import React, { useState, useEffect } from "react";
import API from "../../utils/api";

//sservices
import { fetchStoresService, defaultFetchState, getProductsByStoreId } from "../../utils/services";

// context
import {
  TableContextProvider,
  useTableContext,
} from "../../context/TableContext";
import { useAppContext } from "../../context/Context";
import { useErrorContext } from "../../context/ErrorContext.jsx";

// compoenets
import Button from "../Button";
import Icon from "../Icon";
import Dropdown from "../Dropdown";
import SyncProductsTable from "../table/SyncProductsTable";


const SyncProductsModal = ({ showSyncModal, setShowSyncModal }) => {
  const [productsState, setProductsState] = useState({...defaultFetchState, data:null});
  const [storeUrl, setStoreUrl] = useState("");

  const toggeleModal = () => {
    setShowSyncModal(false);
    setProductsState({...defaultFetchState, data:null});
    setStoreUrl(null);
  };

  return (
    <>
      {!productsState?.data ? (
        <SelectStore
          {...{
            setShowSyncModal,
            showSyncModal,
            productsState, 
            setProductsState,
            storeUrl,
            setStoreUrl,
          }}
        />
      ) : (
        <TableContextProvider
          {...{
            countPerPage: 5,
            selectable: true,
          }}
        >
          <Products {...{ showSyncModal, toggeleModal, storeUrl,productsState, setProductsState }} />
        </TableContextProvider>
      )}
    </>
  );
};

const SelectStore = ({
  setShowSyncModal,
  showSyncModal,
  setProductsState,
  productsState,
  setStoreUrl,
}) => {
  const [loading, setLoading] = useState(false);
  const [storesState, setStoresState] = useState(defaultFetchState);
  const [selectedStore, setSelectedStore] = useState(null);
  const { setStatus } = useErrorContext();

  const handleToggel = () => {
    setShowSyncModal(false);
    setStoreUrl("");
    setLoading(false);
  };

  const handleSyncProducts = async () => {
    try {
      setProductsState({ ...productsState, loading: true });
      setStoreUrl(selectedStore.shopName);
      let id;
      if(selectedStore.platform === "Youcan") {
        id = selectedStore.storeId;
      }
      else {
        id = selectedStore.id;
      }
      const { data, error } = await getProductsByStoreId(id, selectedStore.platform); 
      if (error) {
        setProductsState({ error, loading: false, data: null });
        return 
      }
      setProductsState({ ...productsState, loading: false, data });
      setLoading(false);
    } catch (e) {
      console.log("ERROR!");
      console.log(e);
      setProductsState({ error:e, loading: false, data: null });
     
    }
  };

  useEffect(() => {
    const fetchStoresEffect = async () => {
      setStoresState({ ...storesState, loading: true });
      const stores = await fetchStoresService();
      if (stores.error) {
        return;
      }
      setStoresState(stores);
    };
    fetchStoresEffect();
  }, []);

  return (
    <div
      className={` ${
        showSyncModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
    >
      <div className=" bg-[#F8F9FB] w-[500px] rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 rounded justify-between items-center  bg-white">
              <h1 className="text-[#2b4d75] text-xl font-bold">
                Sync new products
              </h1>
              <button onClick={handleToggel}>
                <Icon name="X" />
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>
          <div className="flex px-5 py-3 flex-col gap-6">
            <Dropdown
              label="Stores"
              isLoading={storesState.loading}
              name="store"
              placeholder=""
              error={`${storesState?.error || ""}`}
              value={selectedStore}
              options={storesState.data}
              onChange={setSelectedStore}
              optionLabel="shopName"
              getOptionValue="id"
            />
          </div>

          <div className="w-full flex justify-end px-5 py-3 border border-[#CAD1DB] bg-white rounded-b">
            <Button
              value={"Add store"}
              onClick={handleSyncProducts}
              disabled={!selectedStore}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Products = ({ showSyncModal, toggeleModal, storeUrl,productsState, setProductsState }) => {
  const {  fetchProductsEffect } = useAppContext();
  const { selectedItems } = useTableContext();
  const [isLoading, setIsLoading] = useState(false);
  const { setStatus } = useErrorContext();
  const handleProductsImport = async () => {
    try {
      setStatus(true);
      setIsLoading(true);
      const response = await API.post(`/addProducts`, {
        products: selectedItems,
        storeName: storeUrl,
      });
      console.log(response);
      setIsLoading(false);
      toggeleModal();
      fetchProductsEffect();
    } catch (e) {
      setIsLoading(false);
      console.log("Error!!");
      console.log(e);
    }
  };

  return (
    <div
      className={` ${
        showSyncModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
    >
      <div className=" bg-[#F8F9FB] w-[1000px] rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 justify-between items-center rounded bg-white">
              <h1 className="text-[#2b4d75] text-xl font-bold">
                Import products
              </h1>
              <button
                onClick={toggeleModal}
                className="hover:rotate-180 origin-center transition"
              >
                <Icon name="X" />
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>

          <div className="flex px-5 py-3 flex-col gap-6">
            <SyncProductsTable {...{productsState, setProductsState}} />
          </div>
          <div className="w-full flex justify-end px-5 py-3 border border-[#CAD1DB] bg-white rounded-b">
            <Button
              value={"Import"}
              onClick={() => handleProductsImport()}
              disabled={selectedItems.length > 0 ? false : true}
              icon={{
                name: "DownloadCloud",
              }}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SyncProductsModal;
