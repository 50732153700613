import { createContext, useContext, useState, useEffect } from "react";
import { useAppContext } from "./Context";

const tableContext = createContext();

export const TableContextProvider = ({
  children,
  countPerPage = 8,
  emptyState,
  selectable,
  isDashboard,
  actions = [],
  onSwitch= () => { }
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const { assignProducts, setAssignProducts } = useAppContext()
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [assigneAd,setAssigneAd]= useState([]) //for ads
  const [campaignData, setCampaignData] = useState(null) //for campaign  


  // const handleSelectAll = () => {
  //   //TODO: handle select all (only filtred data) |  thinking of a better logic
  //   if (!selectedItems.length) {
  //     setSelectedItems(tableData);
  //   } else {
  //     setSelectedItems([]);
  //   }
  // };

  const selectItem = (targetItem) => {
    if (selectedItems.find((item) => item.id === targetItem.id)) {
      setSelectedItems(
        selectedItems.filter((item) => item.id !== targetItem.id)
      );
      setAssignProducts(
        assignProducts.filter((item) => item.id !== targetItem.id)
      );
      setAssigneAd(
        assigneAd.filter((item) => item.id !== targetItem.id)
      )
    } else {
      setSelectedItems([...selectedItems, targetItem]);
      setAssignProducts([...selectedItems, targetItem])
      setAssigneAd([...selectedItems, targetItem])
    }
  };

  // useEffect(() => {
  //   if (!!searchTerm) {
  //     const filtred = tableData.filter((row) =>
  //       Object.values(row).find((value) =>
  //         (value + "").toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  //     );
  //     setFiltredData(filtred);
  //   } else {
  //     setFiltredData(tableData);
  //   }
  // }, [searchTerm, tableData]);


  return (
    <tableContext.Provider
      value={{
        selectedItems,
        setSelectedItems,
        selectItem,
        currentPage,
        setCurrentPage,
        searchTerm,
        setSearchTerm,
        countPerPage,
        emptyState,
        selectable,
        selectedItem,
        setSelectedItem,
        actions,
        assigneAd,
        setAssigneAd,
        setCampaignData,
        campaignData,
        onSwitch,
        isDashboard,
      }}
    >
      {children}
    </tableContext.Provider>
  );
};

export const useTableContext = () => {
  return useContext(tableContext);
};
