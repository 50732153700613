import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className=" text-[#aaa]  py-4" style={{ background: "#080f18" }}>
      <div className={"w-[1000px] m-auto flex  flex-col gap-2"}>
        <header className="entry-header">
          <h1 className="font-extrabold  text-[#efefef] text-yellow font-circularBold text-4xl leading-[150%] mb-4">
            AdClair – Privacy Policy
          </h1>
        </header>
        <div className="entry-content font-walsheim">
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Effective date: May 21, 2024
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            AdClair LTD (“us”, “we”, or “our”) operates the https://adclair.com/
            and https://app.adclair.com/ websites (the “Service”).
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data.
          </p>
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions, accessible from
            https://adclair.com/ and https://app.adclair.com/
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Information Collection And Use
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>{" "}
          <h3 className="font-semibold text-[#efefef]  text-3xl font-walsheim mb-4">
            Types of Data Collected
          </h3>{" "}
          <div className="pl-4">
            <h4 className="font-semibold text-[#efefef]  font-walsheim text-2xl mb-4">
              Personal Data
            </h4>{" "}
            <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to:
            </p>{" "}
            <ul className="list-disc pl-8">
              <li className="mb-2 text-light-600 leading-[200%]">
                Email address
              </li>{" "}
              <li className="mb-2 text-light-600 leading-[200%]">
                First name and last name
              </li>{" "}
              <li className="mb-2 text-light-600 leading-[200%]">
                Cookies and Usage Data
              </li>
            </ul>{" "}
            <h4 className="font-semibold text-[#efefef]  font-walsheim text-2xl mb-4">
              Usage Data
            </h4>{" "}
            <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
              We may also collect information how the Service is accessed and
              used (“Usage Data”). This Usage Data may include information such
              as your computer’s Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </p>{" "}
            <h4 className="font-semibold text-[#efefef]  font-walsheim text-2xl mb-4">
              Tracking &amp; Cookies Data
            </h4>{" "}
            <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.
            </p>{" "}
            <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
              Cookies are files with small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Tracking technologies also
              used are beacons, tags, and scripts to collect and track
              information and to improve and analyze our Service.
            </p>{" "}
            <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>{" "}
            <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
              Examples of Cookies we use:
            </p>{" "}
            <ul className="list-disc pl-8">
              <li className="mb-2 text-light-600 leading-[200%]">
                <strong>Session Cookies.</strong>&nbsp;We use Session Cookies to
                operate our Service.
              </li>{" "}
              <li className="mb-2 text-light-600 leading-[200%]">
                <strong>Preference Cookies.</strong>&nbsp;We use Preference
                Cookies to remember your preferences and various settings.
              </li>{" "}
              <li className="mb-2 text-light-600 leading-[200%]">
                <strong>Security Cookies.</strong>&nbsp;We use Security Cookies
                for security purposes.
              </li>
            </ul>
          </div>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Use of Data
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            AdClair LTD uses the collected data for various purposes:
          </p>{" "}
          <ul className="list-disc pl-8">
            <li className="mb-2 text-light-600 leading-[200%]">
              To provide and maintain the Service
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To notify you about changes to our Service
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To provide customer care and support
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To provide analysis or valuable information so that we can improve
              the Service
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To monitor the usage of the Service
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To detect, prevent and address technical issues
            </li>
          </ul>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Transfer Of Data
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Your information, including Personal Data, may be transferred to â€”
            and maintained on â€” computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you are located outside Morocco and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to Morocco and process it there.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            AdClair LTD will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Disclosure Of Data
          </h2>{" "}
          <h3 className="font-semibold text-[#efefef]  text-3xl font-walsheim mb-4">
            Legal Requirements
          </h3>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            AdClair LTD may disclose your Personal Data in the good faith belief
            that such action is necessary to:
          </p>{" "}
          <ul className="list-disc pl-8">
            <li className="mb-2 text-light-600 leading-[200%]">
              To comply with a legal obligation
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To protect and defend the rights or property of SAdClair LTD
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To protect the personal safety of users of the Service or the
              public
            </li>{" "}
            <li className="mb-2 text-light-600 leading-[200%]">
              To protect against legal liability
            </li>
          </ul>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Security Of Data
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Service Providers
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>{" "}
          <h3 className="font-semibold text-[#efefef]  text-3xl font-walsheim mb-4">
            Analytics
          </h3>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>{" "}
          <ul className="list-disc pl-8">
            <li className="mb-2 text-light-600 leading-[200%]">
              <strong>Google Analytics</strong> is a web analytics service
              offered by Google that tracks and reports website traffic. Google
              uses the data collected to track and monitor the use of our
              Service. This data is shared with other Google services. Google
              may use the collected data to contextualize and personalize the
              ads of its own advertising network.You can opt-out of having made
              your activity on the Service available to Google Analytics by
              installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js, and
              dc.js) from sharing information with Google Analytics about visits
              activity.For more information on the privacy practices of Google,
              please visit the Google Privacy &amp; Terms web page:&nbsp;
              <a
                href="https://policies.google.com/privacy?hl=en"
                className="text-main text-blue"
              >
                https://policies.google.com/privacy?hl=en
              </a>
            </li>
          </ul>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Request that a business that collects a consumer’s personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Request that a business that sells a consumer’s personal data, not
            sell the consumer’s personal data.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            GDPR Data Protection Rights
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Links To Other Sites
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party’s site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Children’s Privacy
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            Our Service does not address anyone under the age of 18
            (“Children”).
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with Personal Data,
            please contact us. If we become aware that we have collected
            Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Changes To This Privacy Policy
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            “effective date” at the top of this Privacy Policy.
          </p>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>{" "}
          <h2 className="font-bold text-[#efefef]  font-circularBold text-4xl leading-[150%] mb-2 text-light-300">
            Contact Us
          </h2>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600">
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>{" "}
          <ul className="list-disc pl-8">
            <li className="mb-2 text-light-600 leading-[200%]">
              By email:&nbsp;
              <a href="mailto:support@adclair.com" className="text-main text-blue">
              support@adclair.com
              </a>
            </li>
          </ul>{" "}
          <p className="text-base font-normal leading-[200%] mb-2 text-light-600"></p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
