import { FilePicker } from "./file-picker";

export default  ({
  value,
  bindContext
}) => {
  return (
      <FilePicker value={value} bindContext={bindContext} />
  );
}

